import React, { useState, useEffect } from 'react'
import DogAccordionItem from './DogAccordionItem'

interface DogsAccordionProps {
  formikProps: {
    errors: any
    touched: any
    setFieldValue: any
    values: any
  }
  maxItems: number
  overrideFirstName?: string
}

const DogsAccordion: React.FC<DogsAccordionProps> = ({ formikProps, maxItems, overrideFirstName }) => {
  const [items, setItems] = useState([0])
  const [addBottomItem, setAddBottomItem] = useState(true)
  const [lastOpt, setLastOpt] = useState('')
  const [lastOpened, setLastOpened] = useState(false)

  const reArrangeFormValues = (removedIndex: number) => {
    if (removedIndex === maxItems) return

    for (let i = removedIndex; i < maxItems; i++) {
      formikProps.setFieldValue([`name${i}`], formikProps.values[`name${i + 1}`])
      formikProps.setFieldValue([`dobYear${i}`], formikProps.values[`dobYear${i + 1}`])
      formikProps.setFieldValue([`dobMonth${i}`], formikProps.values[`dobMonth${i + 1}`])
      formikProps.setFieldValue([`dobDay${i}`], formikProps.values[`dobDay${i + 1}`])
      formikProps.setFieldValue([`gender${i}`], formikProps.values[`gender${i + 1}`])
      formikProps.setFieldValue([`neutered${i}`], formikProps.values[`neutered${i + 1}`])
      formikProps.setFieldValue([`ageStage${i}`], formikProps.values[`ageStage${i + 1}`])
      formikProps.setFieldValue([`weight${i}`], formikProps.values[`weight${i + 1}`])
      formikProps.setFieldValue([`physique${i}`], formikProps.values[`physique${i + 1}`])
      formikProps.setFieldValue([`exercise${i}`], formikProps.values[`exercise${i + 1}`])
      formikProps.setFieldValue(
        [`medicalConditionOrAllergies${i}`],
        formikProps.values[`medicalConditionOrAllergies${i + 1}`]
      )
    }
  }

  const handleSetItems = (opt: string, elem?: number) => {
    setLastOpt(opt)
    if (opt === 'ADD' && items?.length < maxItems) setItems([...items, Math.random()])
    else if (opt === 'REMOVE' && items.length > 1) {
      const filteredItems = items.filter(item => item !== elem)
      const removedIndex = items.indexOf(elem as number)

      if (filteredItems.length === maxItems - 1 && addBottomItem && lastOpened) {
        setItems([...filteredItems, Math.random()])
        setAddBottomItem(false)
      } else {
        setItems(filteredItems)
      }
      reArrangeFormValues(removedIndex + 1)
    }
    setLastOpt(opt)
  }

  useEffect(() => {
    if (items?.length === maxItems && lastOpt === 'ADD') setAddBottomItem(true)
  }, [items, lastOpt])

  useEffect(() => {
    if (!formikProps.values[`name1`]) setItems([...items, Math.random()])
  }, [])

  return (
    <>
      <div>
        {items.map((item, key) => (
          <div
            key={`dogAccordionItem${key}`}
            className={`relative flex w-full min-w-0 flex-col overflow-visible break-words border-0 pb-4`}
          >
            <DogAccordionItem
              isOpened={item === 0}
              item={item}
              index={key}
              formikProps={formikProps}
              handleSetItems={handleSetItems}
              maxItems={maxItems}
              setLastOpened={setLastOpened}
              length={items?.length}
              overrideFirstName={overrideFirstName}
            />
          </div>
        ))}
      </div>
    </>
  )
}

export default DogsAccordion
