export const Faqs = [
  {
    question: 'Why do Different Dog recipes cost more than some other brands?',
    answer: `Unlike many traditional dog foods, we work closely with our trusted network of farmers to ensure our natural ingredients are of the highest quality and our meat is packed with protein. Our unique hand-cooking process preserves each ingredient's innate goodness and transfers their benefits, such as digestive support and boosting overall health, straight to your dog's bowl. So, while the cost of our recipes can be slightly higher, the possibility of healthier, happier and more years with your four-legged best friend is priceless.`
  },
  {
    question: 'How do I make the transition as smooth as possible?',
    answer:
      'We recommend gradually transitioning to our recipes, starting with just 10% and building up daily. You can serve straight from the fridge or slightly heated (great for tempting fussy pups!).'
  },
  {
    question: "What if my dog doesn't like your food?",
    answer:
      'Our customers enjoy licked-clean bowls daily; however, should your dog not enjoy our freshly cooked recipes, our Empty Bowl guarantee protects you — empty bowls on your first order or your money back.'
  },
  {
    question: 'Why does my price show as £0.00?',
    answer:
      "Your bank may show your pending transaction as £0.00 as we aren't quite ready to bill you just yet. Your payment will be taken four days before your next box is due. You can keep an eye on this on your online account."
  }
]
