import { navigate } from '@reach/router'
import { useLocation } from '@reach/router'
import { Cache } from 'aws-amplify'
import TrustPilotMini from 'components/ui-builder/trustpilot/mini'
import ErrorModal from 'components/ui/modal/ErrorModal'
import StickyFooter from 'components/ui/sticky-footer/StickyFooter'
import { getVisitorId } from 'components/util/UserId'
import dayjs from 'dayjs'
import { Field, Formik, useFormikContext } from 'formik'
import FormikErrorFocus from 'formik-error-focus'
import { StaticImage } from 'gatsby-plugin-image'
import { parse, stringify } from 'query-string'
import React, { Dispatch, SetStateAction, useEffect, useState, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'state'
import { createDogs, resetLoadingStatus, updateChangedStatus } from 'state/actions/dogAction'
import { updateCouponHash, updatePlanHash, updateProductHash } from 'state/actions/hashAction'
import { updateKey } from 'state/actions/keyAction'
import { removeAddons, removeRecipes } from 'state/actions/planAction'
import { getProducts } from 'state/actions/productsAction'
import { DogType, PackNewType, SummaryType } from 'state/types/dogType'
import { DogInfoSchema } from 'utils/yup-validation-schema'
import * as yup from 'yup'
import DogsAccordion from './DogsAccordion'

interface DogsCtxState {
  setTotalDogs: Dispatch<SetStateAction<number[]>>
  addDogFunc?: any
  checkIfLastQuestionisMedicalConditions: any
  setIsDisabled: (arg: boolean) => void
}

export const DogsCtx = React.createContext<DogsCtxState>({} as DogsCtxState)

const Dogs = () => {
  const {
    dogInfo,
    coupon,
    summary,
    pack,
    changed,
    loading,
    error,
    errorMessage
  }: {
    dogInfo: DogType
    summary: SummaryType[]
    coupon: any
    pack: any
    changed: boolean
    loading: boolean
    error: boolean
  } = useSelector((state: RootState) => state.dog)

  const { parentDetails } = useSelector((state: RootState) => state.parent)
  const { key } = useSelector((state: RootState) => state.key)
  const { planHash, couponHash, productListHash } = useSelector((state: RootState) => state.hash)
  const products = useSelector((state: RootState) => state.products)
  // Get productListHash from product store
  // TODO: Can be updated once product store in place
  const { productListHash: productHash } = products || { productListHash: '' }
  // const { productListHash } = useSelector((state: RootState) => state.products)
  const dispatch = useDispatch()
  const [totalDogs, setTotalDogs] = useState([1])
  const [showPopup, setShowPopup] = useState(false)
  const [sendConsoleErrorToCloudWatch, setSendConsoleErrorToCloudWatch] = useState(false)
  const location = useLocation()
  const formikRef = useRef<any>()

  const [_userId, setUserId] = useState('')
  const [overrideFirstName, setOverrideFirstName] = useState('')

  let visitorId: string = Cache.getItem('visitorId')
  if (
    [
      '638c084f1f16cf85aed66cf027472004',
      'baefc2443c952ce9109898d9016853d4',
      '696ab977b1e177303a0c0023240ddfdf',
      '814e1afd9f329f4c5aa6e32f9ee9568a',
      '1795d46ba0721e30c39ec35f47e981dd'
    ].includes(visitorId)
  )
    visitorId = ''

  const getUserId = async () => {
    const visitorId = await getVisitorId()
    setUserId(visitorId)
  }

  useEffect(() => {
    if (visitorId) {
      setUserId(visitorId)
    } else {
      getUserId()
    }
  }, [visitorId])

  // Navigate to new dog details page for old sessions
  useEffect(() => {
    dispatch(updateKey('dog-details'))
    navigate(`/signup/dog-details/${location.search && '?' + stringify(parse(location.search))}`)
  }, [])

  useEffect(() => {
    dispatch(resetLoadingStatus())
  }, [])

  useEffect(() => {
    if (errorMessage && error) {
      setShowPopup(true)
    }
  }, [errorMessage])

  useEffect(() => {
    // Populates the product store
    // if (!products)
    dispatch(getProducts())
  }, [])

  useEffect(() => {
    if (summary?.length) {
      let total = []
      for (let i = 1; i <= summary.length; i++) {
        total.push(i)
      }
      setTotalDogs(total)
    }
  }, [summary])

  useEffect(() => {
    const updateKeyAndRedirect = () => {
      dispatch(updateKey('your-details'))
      navigate(`/signup/your-details/${location.search && '?' + stringify(parse(location.search))}`)
    }

    if (changed) {
      if (typeof window.gtag !== 'undefined') {
        if (pack.planLevel) {
          // this updates the ga set (should work, if not, add to script tag in Helmet component)
          window.gtag('set', { dimension4: `${pack.planLevel.toString()}` })
          // this updates the ga send as its an event (may need to change config to event, not sure, try both)
          window.gtag('event', 'Submit', {
            event_category: 'Form',
            event_label: 'Subscription Signup Calculate'
          })
        }

        if (error) {
          window.gtag('event', 'Failure', {
            event_category: 'Form',
            event_label: 'Subscription Signup Calculate'
          })
        }
      }

      if (!error) {
        updateKeyAndRedirect()
      }
    }
    return () => {
      dispatch(updateChangedStatus(false))
    }
  }, [changed, error])

  useEffect(() => {
    if (pack && pack.hash && pack.hash !== planHash) {
      dispatch(updatePlanHash(pack.hash))
    }

    if (coupon && coupon.hash && coupon.hash !== couponHash) {
      dispatch(updateCouponHash(coupon.hash))
    }
    if (productHash && productHash !== productListHash) {
      dispatch(updateProductHash(productHash))
    }
  }, [pack, coupon, planHash, couponHash, productHash, productListHash])

  useEffect(() => {
    if (key !== 'dog-details' && key !== 'dog-details-1') {
      navigate(`/signup/${key}/${location.search && '?' + stringify(parse(location.search))}`)
    }
  }, [])

  useEffect(() => {
    if (sendConsoleErrorToCloudWatch && Object.keys(formikRef.current.errors).length > 0) {
      console.error(JSON.stringify(formikRef.current.errors))
    }
    setSendConsoleErrorToCloudWatch(false)
  }, [sendConsoleErrorToCloudWatch])

  useEffect(() => {
    if (typeof ttq !== 'undefined') {
      ttq.track('ViewContent')
    }

    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'opened_page', {
        page: 'sign_up_dog_details'
      })
    }
  }, [])

  useEffect(() => {
    if (typeof window?.yotpoWidgetsContainer?.initWidget !== 'undefined') {
      window.yotpoWidgetsContainer.initWidgets()
    }
  }, [])

  useEffect(() => {
    const { d: encoded } = parse(location.search)
    if (encoded) setOverrideFirstName(decodeURI(atob(encoded)))
  }, [])

  const handleGetVisitorId = async () => {
    const visitorId = await getVisitorId()
    return visitorId
  }

  const checkIfLastQuestionisMedicalConditions = () => {
    if (
      formikRef.current &&
      formikRef.current.values[`exercise${totalDogs.length}`] &&
      !formikRef.current.values[`medicalConditionOrAllergies${totalDogs.length}`]
    ) {
      return true
    }

    if (formikRef.current && formikRef.current.values[`medicalConditionOrAllergies${totalDogs.length}`]) {
      return true
    }

    return false
  }

  const checkIfMedicalConditionsIsCompleted = () => {
    if (formikRef.current && formikRef.current.values[`medicalConditionOrAllergies${totalDogs.length}`]) {
      return true
    }

    return false
  }

  const validationSchema = Object.assign(
    {},
    ...totalDogs.map(i => ({
      [`name${i}`]: DogInfoSchema.fields.name,
      [`dobYear${i}`]: DogInfoSchema.fields.dob,
      [`dobMonth${i}`]: DogInfoSchema.fields.dobMonth.when(`dobYear${i}`, (value, schema) => {
        const todayMinus1Year = dayjs().subtract(1, 'year')
        if (value && Number(value) >= todayMinus1Year.year()) {
          return schema.required('Month of birth is required')
        }
        return schema
      }),
      [`neutered${i}`]: DogInfoSchema.fields.neutered,
      [`weight${i}`]: DogInfoSchema.fields.weight,
      [`physique${i}`]: DogInfoSchema.fields.physique,
      [`exercise${i}`]: DogInfoSchema.fields.exercise.when(`dobYear${i}`, (value, schema) => {
        const todayMinus1Year = dayjs().subtract(1, 'year')
        return !value || Number(value) <= todayMinus1Year.year() ? schema.required('Exercise is required') : schema
      }),
      [`gender${i}`]: DogInfoSchema.fields.gender,
      [`medicalConditionOrAllergies${i}`]: DogInfoSchema.fields.medicalConditionOrAllergies
    })),
    {
      email: DogInfoSchema.fields.email,
      marketingOptIn: DogInfoSchema.fields.marketingOptIn
    }
  )

  const getDogDateOfBirth = (year: String, month: String, day: String) => {
    let dateString = ''

    if (year) {
      dateString += year
    }

    if (month) {
      dateString += '-' + month
    }

    if (day) {
      dateString += '-' + day
    }

    return dateString
  }

  const addDogFunc: any = React.useRef(null)
  const [isDisabled, setIsDisabled] = useState<boolean>(false)

  return (
    <div className="relative">
      <div className="header-wave-new h-[300px]" />
      <div className="relative mb-8">
        <h1 data-testid="createYourPlanText" className="p-4 pt-10 text-center text-2xl tracking-tight sm:text-5xl">
          Create your plan
        </h1>
        <p className="pb-4 text-center">
          Tell us about your dog and we'll cook up their tailored recipe plan, it takes less than 2 minutes, plus you'll
          get a free treat with your first box.
        </p>
      </div>
      <div>
        <Formik
          initialValues={Object.assign(
            {},
            ...dogInfo.dogs.map((dog, i) => ({
              [`name${i + 1}`]: dog?.name,
              [`dobYear${i + 1}`]: dog?.dob.split('-')[0],
              [`dobMonth${i + 1}`]: dog?.dob.split('-')[1],
              [`gender${i + 1}`]: dog?.gender,
              [`neutered${i + 1}`]: typeof dog?.neutered === 'boolean' ? (dog?.neutered ? 'YES' : 'NO') : '',
              [`weight${i + 1}`]: dog?.weight !== 0 ? dog?.weight : '',
              [`physique${i + 1}`]: dog?.physique,
              [`exercise${i + 1}`]: dog?.exercise,
              [`medicalConditionOrAllergies${i + 1}`]:
                typeof dog?.medicalConditionOrAllergies === 'boolean'
                  ? dog?.medicalConditionOrAllergies
                    ? 'YES'
                    : 'NO'
                  : ''
            })),
            {
              email: dogInfo.customer.email,
              marketingOptIn:
                typeof dogInfo.customer.marketingOptIn === 'boolean' ? dogInfo.customer.marketingOptIn : true
            }
          )}
          validationSchema={yup.object().shape(validationSchema)}
          innerRef={formikRef}
          onSubmit={async (values, { setSubmitting }) => {
            if (typeof window.gtag !== 'undefined') {
              window.gtag('event', 'calculate_plan', {
                page: 'sign_up_dog_details'
              })
            }

            if (typeof ttq !== 'undefined') {
              ttq.track('SubmitForm')
            }

            const dogs: any = []
            ;[1, 2, 3, 4, 5].forEach(i => {
              if (
                values[`name${i}`] &&
                values[`weight${i}`] &&
                values[`dobYear${i}`] &&
                values[`exercise${i}`] &&
                values[`physique${i}`] &&
                values[`neutered${i}`] &&
                values[`medicalConditionOrAllergies${i}`] &&
                values[`gender${i}`]
              ) {
                dogs.push({
                  name: values[`name${i}`],
                  weight:
                    typeof values[`weight${i}`] === 'string' ? parseInt(values[`weight${i}`]) : values[`weight${i}`],
                  dob: getDogDateOfBirth(values[`dobYear${i}`], values[`dobMonth${i}`], values[`dobDay${i}`]),
                  exercise: values[`exercise${i}`],
                  physique: values[`physique${i}`],
                  neutered: values[`neutered${i}`] === 'YES',
                  gender: values[`gender${i}`],
                  medicalConditionOrAllergies: values[`medicalConditionOrAllergies${i}`] === 'YES'
                })
              }
            })

            dispatch(removeRecipes())
            dispatch(removeAddons())

            dispatch(
              createDogs({
                customer: {
                  email: '',
                  marketingOptIn: values.marketingOptIn
                },
                dogs,
                visitorId: await handleGetVisitorId()
              })
            )

            setSubmitting(false)

            if (errorMessage) {
              console.error(JSON.stringify(errorMessage, null, 2))
            }
          }}
          validateOnBlur={true}
        >
          {props => {
            return (
              <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-1 sm:gap-x-8">
                <FormikErrorFocus
                  formik={useFormikContext()}
                  offset={0}
                  align={'middle'}
                  focusDelay={1000}
                  ease={'linear'}
                  duration={1000}
                />
                <DogsCtx.Provider
                  value={{ setTotalDogs, addDogFunc, checkIfLastQuestionisMedicalConditions, setIsDisabled }}
                >
                  <DogsAccordion formikProps={props} maxItems={5} overrideFirstName={overrideFirstName} />
                </DogsCtx.Provider>
                {/* Join the Pack */}
                {
                  <div className="relative bg-white p-4">
                    {formikRef &&
                      formikRef.current &&
                      formikRef.current.values['medicalConditionOrAllergies1'] &&
                      totalDogs.length >= 1 &&
                      checkIfMedicalConditionsIsCompleted() && (
                        <div className="py-12">
                          <div className="flex justify-center py-4 text-center sm:py-8">
                            {props.values.name2 && (
                              <h4 className={`mb-0 self-center font-serif text-2xl sm:text-4xl`}>
                                Ready to see your dogs' plan?
                              </h4>
                            )}{' '}
                            {!props.values.name2 && (
                              <h4 className={`mb-0 self-center font-serif text-2xl sm:text-4xl`}>
                                Ready to see {props.values.name1}'s plan?
                              </h4>
                            )}
                          </div>
                          <div className="flex justify-center py-2">
                            <button
                              onClick={e => {
                                if (typeof window.gtag !== 'undefined') {
                                  window.gtag('event', 'submit_dog_details', {
                                    page: 'sign_up_dog_details'
                                  })
                                }
                                e.isDefaultPrevented()
                                setSendConsoleErrorToCloudWatch(true)
                                props.handleSubmit()
                              }}
                              type="button"
                              data-testid="calculate"
                              className={`${
                                loading || props.isSubmitting ? 'opacity-50' : ''
                              } calculate-plan btn-cta scale-animation w-3/4 items-center md:w-1/3`}
                              disabled={loading || props.isSubmitting}
                            >
                              {loading ? (
                                <div className="align-center flex justify-center">
                                  <div className="border-white-900 mr-2 h-5 w-5 animate-spin rounded-full border-b-2"></div>
                                  <div>Calculating</div>
                                </div>
                              ) : (
                                'Next'
                              )}
                            </button>
                          </div>
                          <div className="flex justify-center p-2">
                            <button
                              className={`mr-2 inline-flex ${
                                isDisabled || (totalDogs.length > 1 && !checkIfMedicalConditionsIsCompleted())
                                  ? 'opacity-60'
                                  : ''
                              }`}
                              onClick={() => {
                                if (typeof window.gtag !== 'undefined') {
                                  window.gtag('event', 'signup_add_dog', {
                                    page: 'sign_up_dog_details'
                                  })
                                }
                                addDogFunc?.current && addDogFunc?.current()
                              }}
                              disabled={isDisabled || (totalDogs.length > 1 && !checkIfMedicalConditionsIsCompleted())}
                            >
                              <div className="mr-4 rounded-full border-2 border-primary">
                                <button
                                  data-testid="btnAddPlus"
                                  type="button"
                                  className={`mx-1 my-1 flex h-[20px] w-[20px] items-center bg-white !px-0 !py-0 !text-4xl text-primary`}
                                  onClick={() => {
                                    if (typeof window.gtag !== 'undefined') {
                                      window.gtag('event', 'signup_add_dog', {
                                        page: 'sign_up_dog_details'
                                      })
                                    }
                                    addDogFunc?.current && addDogFunc?.current()
                                  }}
                                >
                                  +
                                </button>
                              </div>
                              <h4 className={`font-sans-serif mb-0 w-full self-center`}>Add new dog</h4>
                            </button>
                          </div>
                        </div>
                      )}
                  </div>
                }
              </div>
            )
          }}
        </Formik>
      </div>
      {errorMessage && (
        <ErrorModal
          show={showPopup}
          setShow={setShowPopup}
          errorMessage={errorMessage?.popup?.message || errorMessage?.message}
          url={errorMessage?.popup?.url ?? ''}
          action={errorMessage?.popup?.action ?? ''}
          actionText={errorMessage?.popup?.actionText ?? ''}
        />
      )}
      <div className="footer-wave-bottom-signup relative -mb-96 py-16">
        <div className="mb-4 w-full text-center font-serif">
          <h3 className="mb-8">Have you got any questions?</h3>
          <p>
            Call us on{' '}
            <a className="font-bold" href="tel:+441743384562">
              01743 384 562
            </a>
            {/* </p>
          <p> */}{' '}
            or email{' '}
            <a className="font-bold" href="mailto:feedme@differentdog.com">
              feedme@differentdog.com
            </a>{' '}
          </p>
        </div>
        <TrustPilotMini backgroundColor="transparent" />
      </div>
    </div>
  )
}

export default Dogs
