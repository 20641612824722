import TrustPilotMicroCombo from 'components/ui-builder/trustpilot/micro-combo'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useEffect, useState } from 'react'
import { reviews } from './ReviewsData'

interface Review {
  type: string
  name: string
  text: string
  link: string
  generic: boolean
}

export const Reviews = ({ reviewsType }: { reviewsType: string }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [fade, setFade] = useState(true)
  const [selectedReviews, setSelectedReviews] = useState<Review[]>([])

  useEffect(() => {
    // Set the review array based on review type
    let filteredReviews = []
    if (reviewsType === 'generic') {
      filteredReviews = reviews.filter(review => review.generic === true)
    } else {
      filteredReviews = reviews.filter(review => review.type === reviewsType)
    }
    setSelectedReviews(filteredReviews)
    setCurrentIndex(0)
  }, [reviewsType, reviews])

  // Handle the cycling of reviews
  useEffect(() => {
    if (selectedReviews.length === 0) return

    const interval = setInterval(() => {
      setFade(false)
      const timeout = setTimeout(() => {
        setCurrentIndex(prevIndex => (prevIndex + 1) % selectedReviews.length)
        setFade(true)
      }, 1000)
      return () => {
        clearTimeout(timeout)
      }
    }, 10000) // Show for 10 seconds

    return () => clearInterval(interval)
  }, [selectedReviews, currentIndex])

  const currentReview = selectedReviews.length > 0 ? selectedReviews[currentIndex] : null

  useEffect(() => {
    const trustWidget = document.getElementById('trust-pilot-micro-review-count')
    window.Trustpilot?.loadFromElement(trustWidget)
  }, [])

  const getWidget = () => {
    return (
      <div>
        {/* <!-- TrustBox widget - Micro Review Count --> */}
        <div
          className={`trustpilot-widget trustpilot-widget-black px-8 pt-4 pb-2`}
          data-locale="en-GB"
          data-template-id="5419b6a8b0d04a076446a9ad"
          data-businessunit-id="5af423d4a3303b0001bf47c1"
          data-style-height="24px"
          data-style-width="100%"
          data-min-review-count="0"
          data-style-alignment="center"
          data-stars="5"
          data-font-family="Montserrat"
          id="trust-pilot-micro-review-count"
        >
          <a href="https://uk.trustpilot.com/review/differentdog.com" target="_blank" rel="noreferrer noopener">
            <StaticImage
              objectFit="contain"
              className="w-full"
              src="../../../images/trustpilot-single-line.png"
              alt="Trustpilot"
            />
          </a>
        </div>
        {/* <!-- End TrustBox widget --> */}
      </div>
    )
  }

  return (
    <>
      {currentReview && (
        <>
          <div className="relative w-full mx-auto flex flex-col items-center mt-6 bg-[#D7EAD2] rounded-3xl h-40 lg:h-auto">
            <StaticImage
              className="absolute top-0 mt-[-12px]"
              src={'../../../images/trustpilot5Star.svg'}
              alt="5 Stars"
            />
            <div
              className={`flex flex-col justify-center items-center pt-8 lg:pb-6 px-8 transition-opacity duration-1000 ${
                fade ? 'opacity-100' : 'opacity-0'
              }`}
            >
              <a href={currentReview.link} target="_blank" rel="noreferrer noopener" className="no-underline">
                <p className="font-sans font-bold text-base text-center">{currentReview.text}</p>
                <p className="font-sans font-medium text-sm text-center">{currentReview.name}</p>
              </a>
            </div>
          </div>

          <div className="flex justify-center items-center mx-auto my-2">{getWidget()}</div>
        </>
      )}
    </>
  )
}
