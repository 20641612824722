import { Cache } from 'aws-amplify'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { getRemainingTimeUntilMsTimestamp } from './CountdownTimerUtil'

export default function CountdownTimer({ setDisplayDeal }) {
  const key = 'checkout_timer'
  let timer = Cache.getItem(key)
  if (!timer) {
    // start new timer
    timer = new Date()
    Cache.setItem(key, timer)
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'checkout_timer_start', {
        page: 'sign_up_checkout'
      })
    }
  }
  const userTimestamp = dayjs(timer)

  useEffect(() => {
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'checkout_timer_loaded', {
        page: 'sign_up_checkout'
      })
    }
  }, [])

  const getRemainingTime = (countdown: any) => {
    return getRemainingTimeUntilMsTimestamp(false, countdown)
  }

  const defaultRemainingTime = getRemainingTime(userTimestamp) || {
    seconds: '00',
    minutes: '00'
  }

  const [remainingTime, setRemainingTime] = useState(defaultRemainingTime)

  useEffect(() => {
    // Countdown
    const intervalId = setInterval(() => {
      updateRemainingTime(userTimestamp)
    }, 200)
    return () => clearInterval(intervalId)
  }, [])

  useEffect(() => {
    setDisplayDeal(remainingTime.seconds === null || remainingTime.minutes === null ? false : true)
  }, [remainingTime])

  const updateRemainingTime = (countdown: any) => {
    setRemainingTime(getRemainingTimeUntilMsTimestamp(false, countdown))
  }

  return (
    <>
      {(remainingTime.seconds !== null || remainingTime.minutes !== null) && (
        <div className="flex flex-row justify-center items-center">
          <div className="w-8 h-8 bg-white rounded-lg m-2 text-center items-center content-center">
            <p className="mt-[3px] text-center items-center text-xl">{remainingTime.minutes}</p>
          </div>
          <span className="font-bold">:</span>
          <div className="w-8 h-8 bg-white rounded-lg m-2 text-center items-center content-center">
            <p className="mt-[3px] text-center items-center text-xl">{remainingTime.seconds}</p>
          </div>
        </div>
      )}
    </>
  )
}
