import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'

const RemoveDogSignupModal = ({ show, setShow, dogIndex, dogName, setRemoveDogConfirm }) => {
  const dogLabel =
    dogIndex === 2
      ? '2nd dog'
      : dogIndex === 3
        ? '3rd dog'
        : dogIndex === 4
          ? '4th dog'
          : dogIndex === 5
            ? '5th dog'
            : ''
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 -top-40 md:top-0 z-50 overflow-y-auto" onClose={setShow}>
        <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block w-full max-w-2xl transform rounded-3xl bg-white p-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:p-8 sm:align-middle z-50">
              <div>
                <FontAwesomeIcon
                  icon={faClose}
                  fontSize={24}
                  className="absolute top-4 right-4 cursor-pointer md:top-8 md:right-8"
                  onClick={() => setShow(false)}
                />
                <div className="mb-8">
                  <Dialog.Title className="font-sans font-semibold mb-2 text-center text-lg leading-6">
                    Confirm removing {dogName ? dogName : dogLabel}
                  </Dialog.Title>
                  {dogName && (
                    <Dialog.Title className="font-sans font-medium my-8 text-center text-lg leading-6">
                      Your dog's details will not be saved.
                    </Dialog.Title>
                  )}
                </div>
                <div className="flex flex-row justify-around items-center">
                  <div className="">
                    <button
                      type="button"
                      className="w-20 justify-center inline-flex md:w-40 border-black border-b-2"
                      onClick={() => setShow(false)}
                    >
                      No
                    </button>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn-cta w-20 justify-center inline-flex disabled:bg-[#B0CDD5] md:w-40"
                      onClick={() => {
                        setShow(false)
                        setRemoveDogConfirm(true)
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default RemoveDogSignupModal
