import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { Cache } from 'aws-amplify'
import StickyFooter from 'components/ui/sticky-footer/StickyFooter'
import Toast from 'components/ui/toast/Toast'
import { getCognitoId, getVisitorId } from 'components/util/UserId'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { navigate } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'state'
import { resetDogs } from 'state/actions/dogAction'
import { resetKey } from 'state/actions/keyAction'
import { resetParent } from 'state/actions/parentAction'
import { resetPlan } from 'state/actions/planAction'
import { resetShipping } from 'state/actions/shippingAction'
import Api from 'state/apis/api'
import loadingGIF from '../../../images/loadingspinner.gif'
import ReferFriend from '../../../images/refer-friend.png'

dayjs.extend(relativeTime)

const Complete = () => {
  const { orderSummary, shipping }: any = useSelector((state: RootState) => state.shippingConfirm)
  const [howFoundUs, setHowFoundUs] = useState('')
  const [howFoundUsText, setHowFoundUsText] = useState('')
  const [showHowFoundUsTextBox, setShowHowFoundUsTextBox] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [sendingHowFoundUs, setSendingHowFoundUs] = useState(false)
  const [toastMsg, setToastMsg] = useState('')
  const [localPackSize, setLocalPackSize] = useState(null)
  const [localWeeks, setLocalWeeks] = useState(null)
  // const [url, setUrl] = useState('')
  const [localListOfRecipes, setLocalListOfRecipes] = useState<any>([])
  const [localListOfAddons, setLocalListOfAddons] = useState<any>([])
  const [localOrderSummary, setLocalOrderSummary] = useState<any>(null)

  const [_totalAddonPriceDiscount, setTotalAddonPriceDiscount] = useState(0)
  const [_currentPrices, setCurrentPrices] = useState(null)
  const [_ongoingPrices, setOngoingPrices] = useState(null)
  const [_planPriceDiscount, setPlanPriceDiscount] = useState(0)
  const [_pricesError, setPricesError] = useState(false)
  const [thankyouInfo, setThankyouInfo] = useState(null)

  const [sentThankyouForm, setSentThankyouForm] = useState(false)

  const { plan, recipesSelected, addonsSelected, weeks, packType }: any = useSelector((state: RootState) => state.plan)
  const { spoofMode, spoofedUserId } = useSelector((state: RootState) => state.ui)
  const { pricingData } = useSelector((state: RootState) => state.key)
  const { coupon, lastValidatedCoupon, pack }: any = useSelector((state: RootState) => state.dog)
  const dispatch = useDispatch()

  const [_visitorId, setVisitorId] = useState('')
  const [_cognitoId, setCognitoId] = useState('')

  useEffect(() => {
    if (!thankyouInfo) {
      Api.get('wwwREST', '/v1/private/account/thankyou', {})
        .then(res => {
          setThankyouInfo(res)
          setCurrentPrices(res.current)
          setLocalOrderSummary(res)
          setLocalPackSize(res.packSize)
          setLocalWeeks(res.weeks)
          setLocalListOfRecipes(res.recipes)
          setLocalListOfAddons(res.addons)
        })
        .catch(err => {
          console.error('Unable to get thank you info', err)
        })
      return
    }
  }, [thankyouInfo])

  useEffect(() => {
    const callIdsForGtag = async () => {
      setVisitorId(await getVisitorId())
      setCognitoId(await getCognitoId())
    }
    callIdsForGtag()
  }, [])

  // const gbTreatUpsell = useFeatureIsOn('thank-you-treat-upsell')

  const _getTitleFromId = (type: string, id: string) => {
    let result = ''
    if (type === 'recipe') {
      localListOfRecipes.forEach(recipe => {
        if (recipe.id === id) result = recipe.name
      })
    } else {
      localListOfAddons.forEach(addon => {
        if (addon.id === id) result = addon.name
      })
    }
    return result
  }

  const calculateTotalAddonPriceDiscount = (totalAddonPrice: number) => {
    let total = 0

    if (totalAddonPrice > 0 && lastValidatedCoupon.code) {
      if (lastValidatedCoupon.type === 'percentage') {
        total = totalAddonPrice - (lastValidatedCoupon.amount / 100) * totalAddonPrice
      } else {
        total = totalAddonPrice
      }
    }

    return total
  }

  const calculatePlanDiscount = (planPrice: number) => {
    let total = 0

    if (lastValidatedCoupon.code) {
      if (lastValidatedCoupon.type === 'percentage') {
        total = planPrice - (lastValidatedCoupon.amount / 100) * planPrice
      } else {
        total = planPrice - lastValidatedCoupon.amount
      }
    }

    return total
  }

  useEffect(() => {
    if (pricingData) {
      setCurrentPrices(pricingData.current)
      setOngoingPrices(pricingData.ongoing)
      setTotalAddonPriceDiscount(calculateTotalAddonPriceDiscount(pricingData.current.addons))
      setPlanPriceDiscount(calculatePlanDiscount(pricingData.current.plan + pricingData.current.recipes))
      setPricesError(false)
    } else {
      setPricesError(true)
    }
  }, [lastValidatedCoupon])

  useEffect(() => {
    if (orderSummary?.recipes?.length > 0) {
      setLocalOrderSummary(orderSummary)
    }
    if (orderSummary?.recipes?.length === 0) {
      navigate('/account/')
    }

    let _totalAddonPrice = 0
    addonsSelected.map((addon: any) => {
      if (addon.price) {
        _totalAddonPrice += addon.price * addon.quantity
      }
    })

    if (plan.packSize && plan.weeks && plan.recipes) {
      setLocalPackSize(plan.packSize)
      setLocalWeeks(plan.weeks)
      setLocalListOfRecipes(plan.recipes)

      if (plan.addons) {
        setLocalListOfAddons(plan.addons)
      }
    }

    Cache.removeItem('coupon')

    return () => {
      dispatch(resetKey())
      dispatch(resetParent())
      dispatch(resetDogs())
      dispatch(resetPlan())
      dispatch(resetShipping())
    }
  }, [])

  useEffect(() => {
    if (shipping.customer.email && window?.yotpoWidgetsContainer) {
      window.yotpoWidgetsContainer.initWidgets()
    }
  }, [])

  useEffect(() => {
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'signup_checkout_complete_page_load', {
        page: 'sign_up_thankyou'
      })
    }
  }, [])

  useEffect(() => {
    if (howFoundUs === 'Other') setShowHowFoundUsTextBox(true)
    else setShowHowFoundUsTextBox(false)
  }, [howFoundUs])

  // useEffect(() => {
  //   if (typeof window.gtag !== 'undefined' && orderSummary && currentPrices) {
  //     const recipeItems = orderSummary?.recipes?.map(r => {
  //       return {
  //         item_id: r.id,
  //         item_name: r.name,
  //         quantity: r.quantity,
  //         item_list_id: 'recipes',
  //         item_list_name: 'Recipes'
  //       }
  //     })

  //     const addonItems = orderSummary?.addons?.map(a => {
  //       return {
  //         item_id: a.id,
  //         item_name: a.name,
  //         price: (a.price / 100).toFixed(2),
  //         quantity: a.quantity,
  //         item_list_id: 'addons',
  //         item_list_name: 'Addons'
  //       }
  //     })

  //     // Sending in ShippingConfirm instead
  //     // window.gtag('event', 'purchase', {
  //     //   transaction_id: orderSummary?.subscriptionId,
  //     //   value: (currentPrices?.total / 100).toFixed(2),
  //     //   planLevel: pack?.planLevel,
  //     //   shipping: currentPrices?.shipping,
  //     //   currency: 'GBP',
  //     //   coupon: lastValidatedCoupon?.code,
  //     //   items: recipeItems.concat(addonItems)
  //     // })
  //   }
  // }, [orderSummary, currentPrices])

  const handleSendReason = () => {
    setSendingHowFoundUs(true)
    Api.post('wwwREST', '/v1/private/account/thankyou', { body: { reason: howFoundUs, text: howFoundUsText } })
      .then(() => {
        setSendingHowFoundUs(false)
        setToastMsg('Thank you for letting us know!')
        setShowToast(true)
        setSentThankyouForm(true)
      })
      .catch(err => {
        setSendingHowFoundUs(false)
        setToastMsg(err.message)
        setShowToast(true)
      })
  }

  const handleSetShowToast = (show: boolean) => {
    setShowToast(show)
  }

  return (
    <div className="relative m-4 mb-0">
      <div className="header-wave -m-4 h-[400px] !w-[calc(100%+2rem)] md:h-[250px]" />
      {/* <Helmet>
        <script type="text/javascript" src={url}></script>
      </Helmet> */}
      {shipping.customer.email && (
        <>
          <div
            id="swell-customer-identification"
            data-authenticated="true"
            data-email={shipping?.customer?.email}
            data-id={orderSummary.customerId}
            className="display:none;"
          ></div>
          {process.env.GATSBY_YOTPO_REFERRAL_THANK_YOU && (
            <div
              className="yotpo-widget-instance"
              data-yotpo-instance-id={process.env.GATSBY_YOTPO_REFERRAL_THANK_YOU}
            ></div>
          )}
        </>
      )}
      <div className="relative flex w-full justify-center">
        <StaticImage className="h-14 w-14" src="../../../../images/heart-logo.png" alt="Different Dog" />
      </div>
      <h2
        data-testid="thankYouForYourOrder"
        className="relative flex justify-center py-4 text-center text-3xl sm:text-5xl"
      >
        Thank you for your order
      </h2>
      {/* {gbTreatUpsell && (
        <div className="container relative mx-auto mt-32 mb-12 flex h-auto w-full flex-col overflow-hidden rounded-[30px] bg-checkoutBG md:h-64 md:flex-row lg:h-56">
          <div className="mt-3 flex w-full flex-col items-center text-center md:ml-4 md:mt-2 lg:ml-8 lg:mt-6 lg:w-1/2 lg:items-start lg:text-left">
            <h2 className="z-2 relative my-4 text-xl sm:text-3xl">How about some Treats?</h2>
            <p className="z-2 relative mb-4 px-4 text-base lg:px-0">
              Level up your training with natural, high-value treats and boost their health with nutritionist-approved
              probiotics.
            </p>
            {orderSummary?.subscriptionId && (
              <button
                className="btn-cta mt-4 w-2/3 md:mt-0  lg:w-1/3"
                onClick={() => {
                  navigate(`/account/add-treats`)
                  if (typeof window.gtag !== 'undefined') {
                    window.gtag('event', 'treat_upsell_cta_click', {
                      page: 'sign_up_thankyou'
                    })
                  }
                }}
              >
                Add treats
              </button>
            )}
          </div>
          <div className="relative mt-8 w-full md:mt-0 md:justify-end lg:w-1/2">
            <StaticImage
              objectFit="cover"
              className="bottom-0 h-56 w-full md:h-64 lg:h-56"
              src="../../../images/thankyou-treats-dog.png"
              alt="Treats"
            />
          </div>
        </div>
      )}
      {!gbTreatUpsell && ( */}
      <div className="relative overflow-hidden">
        <div className="thank-you-dog-bg m-auto w-full pb-48 md:w-3/4 md:pb-0">
          <h2 className="z-2 relative mt-12 mb-4 text-3xl sm:text-5xl md:mt-32">Welcome to the pack!</h2>
          <h3 className="z-2 relative mb-4 text-2xl">We're delighted to start cooking for your pup.</h3>
        </div>
      </div>
      {/* )} */}

      <div className="container relative m-auto mb-8 flex w-full flex-row flex-wrap gap-y-5 rounded-3xl border border-third bg-thankyouBG p-8 shadow-xl">
        {localOrderSummary && (
          <div className="order-1 flex w-full flex-col md:w-1/2 md:pr-4">
            <h4 className="mb-4 text-xl">Recipes</h4>
            <div className="flex flex-col gap-y-1">
              {localOrderSummary.recipes.map(recipe => (
                <p className="flex w-full flex-row justify-between text-lg">
                  <span>{recipe.name}</span> {/* <span>{getTitleFromId('recipe', recipe.id)}</span>{' '} */}
                  <span>
                    {localPackSize}g x {recipe.quantity}
                  </span>
                </p>
              ))}
            </div>
          </div>
        )}

        {localOrderSummary && (
          <div className="relative order-4 flex w-full flex-col md:order-2 md:w-1/2 md:pl-4">
            <h4 className="mb-4 text-xl">Delivery Summary</h4>
            <div>
              <p className="mb-4 font-bold">
                Delivery on the {dayjs(localOrderSummary.deliveryDate).format('DD-MMMM-YYYY')}{' '}
              </p>
              <p className="mb-4">Your ongoing deliveries will then be every {localWeeks} weeks.</p>
              <p className="mb-4 text-sm">If you need to change anything, click Go to Account below</p>
            </div>
          </div>
        )}

        {localOrderSummary && localOrderSummary.addons?.length > 0 && (
          <div className="relative order-2 flex w-full flex-col md:order-3 md:w-1/2 md:pr-4">
            <div>
              <h4 className="mb-4 text-xl">Treats</h4>
              <div className="flex flex-col gap-y-1">
                {localOrderSummary &&
                  localOrderSummary.addons.map(addon => (
                    <div>
                      <p className="mb-4 flex w-full flex-row justify-between text-lg">
                        <span>{addon.name}</span> <span>x {addon.quantity}</span>
                        {/* <span>{getTitleFromId('addon', addon.id)}</span> <span>x {addon.quantity}</span> */}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}

        {/* {!currentPrices && !pricesError && (
          <div className="relative order-3 flex w-full flex-col md:order-3 md:w-1/2 md:pr-4">
            <div className="m-auto w-full text-center" style={{ marginTop: '5vh' }}>
              <img className="m-auto" width="200px" src={loadingGIF} alt="Loading, please wait..." />
            </div>
          </div>
        )} */}

        {/* {pricesError && (
          <div className="relative order-3 flex w-full flex-col md:order-3 md:w-1/2 md:pr-4">
            <p>There has been an error retrieving the plan price totals.</p>
          </div>
        )} */}

        {/* {currentPrices && !pricesError && (
          <div className="relative order-3 flex w-full flex-col md:order-3 md:w-1/2 md:pr-4">
            <div>
              <h4 className="mb-4 text-xl">Today's Total</h4>
              <div className="flex flex-col gap-y-1">
                <div className="flex justify-between text-lg">
                  <div>
                    {packType === 'FULL_TRIAL' || packType === 'PARTIAL_TRIAL'
                      ? 'Starter Box'
                      : weeks + ' weeks hand-cooked food'}
                  </div>

                  <div className="flex min-w-max justify-items-end">
                    {coupon.code && (
                      <span className="line-through">
                        £{((currentPrices?.plan + currentPrices?.recipes) / 100).toFixed(2)}
                      </span>
                    )}
                    <span className="pl-1 font-bold">£{(planPriceDiscount / 100).toFixed(2)}</span>
                  </div>
                </div>

                <div className="flex justify-between text-lg">
                  <div>Treats &amp; Extras</div>
                  <div>
                    {coupon.code && <span className="line-through">£{(currentPrices?.addons / 100)?.toFixed(2)}</span>}
                    <span className="font-bold"> £{(totalAddonPriceDiscount / 100).toFixed(2)}</span>
                  </div>
                </div>
                <div className="flex justify-between text-lg">
                  <div>Delivery</div>
                  <div>
                    {currentPrices?.delivery > 0 && (
                      <div>
                        {currentPrices?.discount > 0 && (
                          <span data-testid="deliveryCharge" className="line-through">
                            £{(currentPrices?.delivery / 100).toFixed(2)}
                          </span>
                        )}
                        <span data-testid="deliveryCharge" className="pl-1 font-bold uppercase">
                          £{calculatePlanDiscount(currentPrices?.delivery / 100).toFixed(2)}
                        </span>
                      </div>
                    )}
                    {currentPrices?.delivery === 0 && (
                      <div>
                        <span data-testid="linethrough3.99" className="line-through">
                          £3.99
                        </span>
                        <span className="pl-1 font-bold uppercase">£0.00</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex justify-between text-lg font-bold">
                  <div>{'Total'}</div>
                  <div>
                    <span>£{(currentPrices?.total / 100)?.toFixed(2)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )} */}

        {thankyouInfo?.shipping?.name && (
          <div className="relative order-5 flex w-full flex-col md:order-3 md:w-1/2 md:pl-4">
            <h4 className="mb-4 text-xl">Your Delivery Details</h4>
            <div className="flex flex-col gap-y-1 text-lg">
              <p className="font-semibold">
                {thankyouInfo?.shipping?.name.replace(/( ?)PHONE PAYMENT/g, '') + ' ' + thankyouInfo?.shipping?.surname}
              </p>
              <p>{thankyouInfo?.shipping?.address1}</p>
              <p>{thankyouInfo?.shipping?.address2}</p>
              <p>{thankyouInfo?.shipping?.city}</p>
              <p>{thankyouInfo?.shipping?.shipping?.county}</p>
              <p>{thankyouInfo?.shipping?.postcode}</p>
            </div>
          </div>
        )}

        <div className="relative order-6 flex w-full justify-end">
          <button
            data-testid="goToAccount"
            id="goToAccount"
            type="button"
            className="btn scale-animation inline-flex justify-center rounded-full border border-transparent bg-primary py-2 px-4 font-medium text-white shadow-sm hover:bg-base focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
            onClick={(_e: any) => {
              // Commented out for if we need to revert
              // if (typeof window.gtag !== 'undefined') {
              //   window.gtag('event', 'clicked_go_to_profile', {
              //     page: 'sign_up_thankyou'
              //   })
              // }
              if (typeof window.gtag !== 'undefined') {
                window.gtag('event', 'clicked_go_to_welcome_page', {
                  page: 'sign_up_thankyou'
                })
              }
              // navigate('/account/')
              navigate('/welcometothepack')
            }}
          >
            {/* Go to Account */}
            Let's get started
          </button>
        </div>
      </div>

      <div className="container relative m-auto mb-8 flex w-full flex-col justify-between gap-5 md:flex-row">
        {!sentThankyouForm && (
          <div className="flex w-full flex-col rounded-3xl border border-third bg-thankyouBG p-8 shadow-xl md:w-1/2">
            <h3 className="mb-4 text-xl">How did you hear about us?</h3>
            <div className="text-lg leading-8">
              <div className="form-check flex">
                <input
                  className="form-check-input checked:bg-primay float-left my-auto mr-2 h-5 w-5 cursor-pointer appearance-none rounded-full border bg-white bg-contain bg-center bg-no-repeat align-top text-primary transition duration-200 checked:border-primary focus:outline-none"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  onClick={() => setHowFoundUs('Social Media')}
                />
                <label className="form-check-label inline-block" htmlFor="flexRadioDefault1">
                  Social Media
                </label>
              </div>
              <div className="form-check flex">
                <input
                  className="form-check-input checked:bg-primay float-left my-auto mr-2 h-5 w-5 cursor-pointer appearance-none rounded-full border border-base bg-white bg-contain bg-center bg-no-repeat align-top text-primary transition duration-200 checked:border-primary focus:outline-none"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  onClick={() => setHowFoundUs('Google')}
                />
                <label className="form-check-label inline-block" htmlFor="flexRadioDefault2">
                  Google
                </label>
              </div>
              <div className="form-check flex">
                <input
                  className="form-check-input checked:bg-primay float-left my-auto mr-2 h-5 w-5 cursor-pointer appearance-none rounded-full border border-base bg-white bg-contain bg-center bg-no-repeat align-top text-primary transition duration-200 checked:border-primary focus:outline-none"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault3"
                  onClick={() => setHowFoundUs('Through a friend')}
                />
                <label className="form-check-label inline-block" htmlFor="flexRadioDefault3">
                  Through a friend
                </label>
              </div>
              <div className="form-check flex">
                <input
                  className="form-check-input checked:bg-primay float-left my-auto mr-2 h-5 w-5 cursor-pointer appearance-none rounded-full border border-base bg-white bg-contain bg-center bg-no-repeat align-top text-primary transition duration-200 checked:border-primary focus:outline-none"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault4"
                  onClick={() => setHowFoundUs('Recommended by an expert')}
                />
                <label className="form-check-label inline-block" htmlFor="flexRadioDefault4">
                  Recommended by an expert
                </label>
              </div>
              <div className="form-check flex">
                <input
                  className="form-check-input checked:bg-primay float-left my-auto mr-2 h-5 w-5 cursor-pointer appearance-none rounded-full border border-base bg-white bg-contain bg-center bg-no-repeat align-top text-primary transition duration-200 checked:border-primary focus:outline-none"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault5"
                  onClick={() => setHowFoundUs('At an event')}
                />
                <label className="form-check-label inline-block" htmlFor="flexRadioDefault5">
                  At an event
                </label>
              </div>
              <div className="form-check flex">
                <input
                  className="form-check-input checked:bg-primay float-left my-auto mr-2 h-5 w-5 cursor-pointer appearance-none rounded-full border border-base bg-white bg-contain bg-center bg-no-repeat align-top text-primary transition duration-200 checked:border-primary focus:outline-none"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault6"
                  onClick={() => setHowFoundUs('Podcast')}
                />
                <label className="form-check-label inline-block" htmlFor="flexRadioDefault6">
                  Podcast
                </label>
              </div>
              <div className="form-check flex">
                <input
                  className="form-check-input checked:bg-primay float-left my-auto mr-2 h-5 w-5 cursor-pointer appearance-none rounded-full border border-base bg-white bg-contain bg-center bg-no-repeat align-top text-primary transition duration-200 checked:border-primary focus:outline-none"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault7"
                  onClick={() => setHowFoundUs('Radio')}
                />
                <label className="form-check-label inline-block" htmlFor="flexRadioDefault7">
                  Radio
                </label>
              </div>
              <div className="form-check flex">
                <input
                  className="form-check-input checked:bg-primay float-left my-auto mr-2 h-5 w-5 cursor-pointer appearance-none rounded-full border border-base bg-white bg-contain bg-center bg-no-repeat align-top text-primary transition duration-200 checked:border-primary focus:outline-none"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault8"
                  onClick={() => setHowFoundUs('Leaflet from another business')}
                />
                <label className="form-check-label inline-block" htmlFor="flexRadioDefault8">
                  Leaflet from another business
                </label>
              </div>
              <div className="form-check flex">
                <input
                  className="form-check-input checked:bg-primay float-left my-auto mr-2 h-5 w-5 cursor-pointer appearance-none rounded-full border border-base bg-white bg-contain bg-center bg-no-repeat align-top text-primary transition duration-200 checked:border-primary focus:outline-none"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault9"
                  onClick={() => setHowFoundUs('Other')}
                />
                <label className="form-check-label inline-block" htmlFor="flexRadioDefault9">
                  Other
                </label>
              </div>
              {showHowFoundUsTextBox && (
                <div className="mt-3">
                  <textarea onChange={e => setHowFoundUsText(e.target.value)}></textarea>
                </div>
              )}
              <div className="mt-4 text-end">
                <button
                  type="button"
                  disabled={howFoundUs === ''}
                  className="btn inline-flex justify-center rounded-3xl border border-transparent bg-primary py-2 px-6 font-medium text-white shadow-sm hover:bg-base focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                  onClick={handleSendReason}
                >
                  {sendingHowFoundUs ? (
                    <div className="align-center flex justify-center">
                      <div className="border-white-900 mr-2 h-5 w-5 animate-spin rounded-full border-b-2"></div>
                      <div>Submitting</div>
                    </div>
                  ) : (
                    'Submit'
                  )}
                </button>
              </div>
            </div>
          </div>
        )}
        {sentThankyouForm && (
          <div className="flex w-full flex-col rounded-3xl border border-third bg-thankyouBG p-8 shadow-xl md:w-1/2">
            <p>Answer submitted. Thank you</p>
          </div>
        )}

        <div className="relative flex w-full flex-col items-stretch rounded-3xl border border-third bg-thankyouBG px-6 py-8 md:w-1/2">
          <div className="font-serif-regular">
            <h1 className="text-4xl font-extrabold sm:text-5xl">Earn Free Food</h1>
          </div>
          <p className="mt-5 w-full text-base leading-relaxed sm:max-w-xs sm:text-lg sm:leading-loose">
            Tell your friends about us, and we'll give you £5 off for every box they order.
          </p>
          <div className="mt-4 flex flex-grow flex-row items-end justify-between">
            <div className="w-1/2" style={{ maxWidth: '300px' }}>
              <img src={ReferFriend} alt="Refer a friend" />
            </div>
            <div>
              <button
                className="btn mt-5 inline-flex justify-center rounded-3xl border border-transparent bg-primary py-2 px-6 font-medium text-white shadow-sm hover:bg-base focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                onClick={() => {
                  document?.getElementsByClassName('btn btn-custom').length &&
                    (document?.getElementsByClassName('btn btn-custom')[0] as any)?.click()
                  navigate('/account/rewards/')
                }}
              >
                Learn more
              </button>
            </div>
          </div>
        </div>
      </div>
      <Toast showToast={showToast} msg={toastMsg} handleSetShowToast={handleSetShowToast} />
    </div>
  )
}
export default Complete
