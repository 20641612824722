export const createTemporaryPassword = (passwordLength: number) => {
  if (process.env.GATSBY_TEMP_PASSWORD) {
    return process.env.GATSBY_TEMP_PASSWORD
  }
  const capitalCharset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const lowercaseCharset = 'abcdefghijklmnopqrstuvwxyz'
  const numberSet = '0123456789'
  const specialCharacters = '@#$!?£&*.'
  try {
    let password = ''
    // Make sure password has at lease one of the required characters
    password += capitalCharset.charAt(Math.floor(Math.random() * capitalCharset.length))
    password += lowercaseCharset.charAt(Math.floor(Math.random() * lowercaseCharset.length))
    password += numberSet.charAt(Math.floor(Math.random() * numberSet.length))
    password += specialCharacters.charAt(Math.floor(Math.random() * specialCharacters.length))

    // Generate the remaining characters
    for (let i = 0; i < passwordLength - 4; i++) {
      password += lowercaseCharset.charAt(Math.floor(Math.random() * lowercaseCharset.length))
    }
    return password
  } catch (err) {
    console.error('create temporary password error', err)
    return null
  }
}
