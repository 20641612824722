export const formValidationErrors = [
  {
    key: 'name',
    error: 'Name is required'
  },
  {
    key: 'gender',
    error: 'Gender is required'
  },
  {
    key: 'breed',
    error: 'Breed is required'
  },
  {
    key: 'neutered',
    error: 'Neutered is required'
  },
  {
    key: 'dobYear',
    error: 'Year of birth is required'
  },
  {
    key: 'dobMonth',
    error: 'Month of birth is required'
  },
  {
    key: 'weight',
    error: 'Weight is required'
  },
  {
    key: 'physique',
    error: 'Physique is required'
  },
  {
    key: 'exercise',
    error: 'Exercise is required'
  },
  {
    key: 'medicalConditionOrAllergies',
    error: 'Medical conditions or allergies is required'
  },
  {
    key: 'fussy',
    error: 'Eating preference is required'
  }
]

export const pageSections = [
  {
    value: 'name',
    section: 1
  },
  {
    value: 'gender',
    section: 2
  },
  {
    value: 'breed',
    section: 3
  },
  {
    value: 'neutered',
    section: 4
  },
  {
    value: 'dobYear',
    section: 5
  },
  {
    value: 'weight',
    section: 6
  },
  {
    value: 'physique',
    section: 7
  },
  {
    value: 'exercise',
    section: 8
  },
  {
    value: 'medicalConditionOrAllergies',
    section: 9
  },
  {
    value: 'fussy',
    section: 10
  },
  {
    value: 'next',
    section: 11
  }
]

export const puppyPageSections = [
  {
    value: 'name',
    section: 1
  },
  {
    value: 'gender',
    section: 2
  },
  {
    value: 'breed',
    section: 3
  },
  {
    value: 'neutered',
    section: 4
  },
  {
    value: 'dobYear',
    section: 5
  },
  {
    value: 'weight',
    section: 6
  },
  {
    value: 'physique',
    section: 7
  },
  {
    value: 'medicalConditionOrAllergies',
    section: 8
  },
  {
    value: 'fussy',
    section: 9
  },
  {
    value: 'next',
    section: 10
  }
]

// months for dog DOB
export const months = [
  {
    value: '01',
    month: 'January'
  },
  {
    value: '02',
    month: 'February'
  },
  {
    value: '03',
    month: 'March'
  },
  {
    value: '04',
    month: 'April'
  },
  {
    value: '05',
    month: 'May'
  },
  {
    value: '06',
    month: 'June'
  },
  {
    value: '07',
    month: 'July'
  },
  {
    value: '08',
    month: 'August'
  },
  {
    value: '09',
    month: 'September'
  },
  {
    value: '10',
    month: 'October'
  },
  {
    value: '11',
    month: 'November'
  },
  {
    value: '12',
    month: 'December'
  }
]

// Info box details
export const infoBoxes = [
  {
    section: 'Name',
    image: 'Name',
    imageAlt: 'Meat',
    headline: 'Did you know?',
    text: 'Our 13 delicious recipes are hand cooked with prime cuts of 100% British meat alongside nutritious fruit, veg and superfoods.',
    showOnMobile: true
  },
  {
    section: 'Gender',
    image: 'Gender',
    imageAlt: 'Dog food',
    headline: 'Did you know?',
    text: 'Each meal is frozen shortly after cooking to naturally preserve the goodness in each ingredient and simplify storage at home.',
    showOnMobile: true
  },
  {
    section: 'Breed',
    image: 'Breed',
    imageAlt: 'Dog waiting for bowl of food',
    headline: 'Did you know?',
    text: "Studies have shown feeding a freshly cooked diet can increase your dog's life by up to 2 years, compared to feeding kibble only."
  },
  {
    section: 'Neutered',
    image: 'Neutered',
    imageAlt: 'Dog waiting for bowl of food',
    headline: 'Did you know?',
    text: "Neutering can often lead to a decrease in your dog's metabolism, meaning they may not require as much food to thrive.",
    showOnMobile: true
  },
  {
    section: 'DOB',
    image: 'DOB',
    imageAlt: 'Dog',
    headline: 'Did you know?',
    text: '12 of our 13 recipes are suitable for all ages, including puppies over eight weeks, adult dogs and golden oldies.',
    showOnMobile: false
  },
  {
    section: 'Weight',
    image: 'Weight',
    imageAlt: 'Dog',
    headline: 'Did you know?',
    text: 'A massive 92% of owners report seeing health improvements in their dog since feeding our recipes.',
    showOnMobile: true
  },
  {
    section: 'Physique',
    image: 'Physique',
    imageAlt: 'Nutritionist',
    headline: 'Need some help?',
    text: 'Would you like a personalised plan to help your dog gain or lose a few pounds?',
    showOnMobile: false
  },
  {
    section: 'Exercise',
    image: 'Exercise',
    imageAlt: 'Dog food',
    headline: 'Did you know?',
    text: 'Serve straight from the fridge or drive them wild with even more delicious aromas by heating one portion in the microwave (a great hack for fussy eaters!).',
    showOnMobile: false
  },
  {
    section: 'MedicalConditionOrAllergies',
    image: 'MedicalConditionOrAllergies',
    imageAlt: 'Vet',
    headline: 'Need some help?',
    text: 'Our Health+ range supports a number of conditions;',
    showOnMobile: true
  }
]
