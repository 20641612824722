import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

export const faqs = [
  {
    question: `How did you calculate the feeding amount?`,
    answer: `All dogs are unique, which is what makes them so special! We use your dog's unique information like age and activity level to calculate their plan. We work closely with our in-house vet, nutritionist and behaviourist to ensure this is as accurate as possible. But you know your dog best! Which is why your plan is fully customisable - once you've signed up you'll be able to make changes in your account section to future boxes.`
  },
  {
    question: `Should I feed a full or partial diet?`,
    answer: `Our freshly cooked recipes can be served as a full, complete diet or as a topper alongside another food, depending on your preference and your dog's needs. Of course, feeding our recipes as a full diet brings more opportunity for maximum health benefits; however, recent studies have shown that feeding a partial diet of freshly cooked food, versus a full diet of kibble, can increase your dog's lifespan significantly.`
  },
  {
    question: `Can Different Dog be fed alongside Dry, wet or raw food?`,
    answer: `Yes, you can safely serve our freshly cooked recipes alongside dry, wet, or raw dog food, as the digestion rate of our food is consistent with these diets, ensuring that your dog can enjoy a mix with a low risk of digestive issues. If your dog is prone to a sensitive stomach, we recommend chatting to our in-house experts, who will create a personalised transition programme to support your dog while introducing their new diet.`
  },
  {
    question: `What is the nutritional value and is it good for my dog?`,
    answer: (
      <div>
        <p>
          All of our recipes are nutritionally complete, with 12 of 13 suitable for puppies over 8 weeks old (Low Purine
          and Phosphorus is our only dish recommended for adult dogs only). You can find a full breakdown of each
          recipe's nutritional information, ingredients and calories{' '}
          <a href="/recipes" target={'_blank'} rel="noreferrer">
            here
          </a>
          .
        </p>
      </div>
    )
  },
  {
    question: 'How many packs can I fit in my freezer?',
    answer: (
      <div>
        <div className="flex w-full flex-row">
          <div className="flex flex-col items-end justify-end">
            <p className="hidden w-full p-4 text-right font-bold md:flex md:w-2/3">Integrated fridge freezer</p>
          </div>
          <div className="mr-1 text-center md:mr-8">
            <p className="w-full p-4 font-bold md:hidden">Integrated fridge freezer</p>
            <StaticImage
              className="min-w-[50px]"
              src="../../../../images/faq/faq-1.svg"
              alt="Integrated fridge freezer"
            />
          </div>
          <div className="ml-1 text-center md:ml-8">
            <p className="w-full p-4 font-bold md:hidden">Freestanding fridge freezer</p>
            <StaticImage
              className="min-w-[50px]"
              src="../../../../images/faq/faq-2.svg"
              alt="Freestanding fridge freezer"
            />
          </div>
          <div className="flex flex-col justify-end">
            <p className="hidden w-full p-4 font-bold md:flex md:w-2/3">Freestanding fridge freezer</p>
          </div>
        </div>
        <div className="flex w-full flex-row justify-center">
          <div className="relative my-4 flex w-full flex-row justify-center gap-16 md:left-[-0.5rem] md:w-[280px]">
            <div className="flex">
              <p className="text-center">1 freezer shelf can fit up to:</p>
            </div>
            <div className="flex">
              <p className="text-center">1 freezer shelf can fit up to:</p>
            </div>
          </div>
        </div>
        <div className="relative mb-4 flex flex-col items-center justify-center gap-4">
          <div className="flex w-full flex-row text-center md:w-[380px] md:justify-start">
            <p className="mr-4 font-bold md:mr-12">250g</p>
            <span className="flex w-full flex-row justify-between pr-16 md:w-auto md:pr-0">
              <p className="md:mr-40">7</p>
              <p>10</p>
            </span>
          </div>
          <div className="flex w-full flex-row text-center md:w-[380px] md:justify-start">
            <p className="mr-4 font-bold md:mr-12">600g</p>
            <span className="flex w-full flex-row justify-between pr-16 md:w-auto md:pr-0">
              <p className="md:mr-40">4</p>
              <p>9</p>
            </span>
          </div>
          <div className="flex w-full flex-row text-center md:w-[392px] md:justify-start">
            <p className="mr-4 font-bold md:mr-12">1000g</p>
            <span className="flex w-full flex-row justify-between pr-16 md:w-auto md:pr-0">
              <p className="md:mr-40">3</p>
              <p>7</p>
            </span>
          </div>
          <p className="text-xs italic">Internal dimensions may vary, all numbers are approximate</p>
        </div>
        <div>
          <p className="mb-4">
            Depending on the size of your freezer, most Starter Boxes will fit in 1-2 drawers. Our food can be stored
            alongside your normal food in the fridge or freezer.
          </p>
          <p>
            In hot weather spells we put extra ice packs in your box to keep the food cool. On really hot days our food
            may arrive defrosted but as long as the packs are still cool to touch, its completely fine for you to
            refreeze.
          </p>
        </div>
      </div>
    )
  },
  {
    question: 'How big are the packs of food?',
    answer: (
      <div>
        <p className="mb-4 text-sm font-bold">Approximate dimensions for our pack sizes (in cm):</p>
        <div className="mb-4 flex w-full flex-row flex-wrap justify-evenly gap-y-4">
          <div className="flex w-1/2 flex-col md:w-1/3">
            <p className="font-bold">250g</p>
            <p>14 x 14 x 5 (L x W x H)</p>
            <StaticImage className="w-full md:w-4/5" src="../../../../images/faq/faq-250g.png" alt="250g pack" />
          </div>

          <div className="flex w-1/2 flex-col md:w-1/3">
            <p className="font-bold">600g</p>
            <p>24 x 13 x 4 (L x W x H)</p>
            <StaticImage className="w-full md:w-4/5" src="../../../../images/faq/faq-600g.png" alt="250g pack" />
          </div>

          <div className="flex w-1/2 flex-col md:w-1/3">
            <p className="font-bold">1000g</p>
            <p>22 x 17 x 5 (L x W x H)</p>
            <StaticImage className="w-full md:w-4/5" src="../../../../images/faq/faq-1000g.png" alt="1000g pack" />
          </div>
        </div>
        <p>Our food is delivered frozen for freshness and can be kept in the freezer for up to 6 months.</p>
        <p className="mb-4">When needed, defrost and store in the fridge where they can be kept for up to 5 days.</p>
        <p>After your first box, you can change your pack size, quantity and frequency in your account section.</p>
      </div>
    )
  },
  {
    question: 'Why does the Starter Box have less food than my ongoing order?',
    answer:
      "Don't worry, your dog won't go hungry! Our starter box is a smaller amount of food to help your dog transition onto a new diet. In your box you'll receive a transition plan which details how to mix your Different Dog food with your current food. This allows your dog to get used to the new flavours, textures and smells."
  }
  // {
  //   question: `Does DD clean teeth?`,
  //   answer: `Overall health includes gum and dental health, so feeding Different Dog’s high-quality, balanced recipes is a great start. We include kelp in all of our recipes which gives our food a gritty texture and causes positive abrasive action against the teeth, helping to remove food buildup and plaque.
  //   As with any diet, frequently brushing your dog’s teeth can also boost dental hygiene and lessen the bacteria that can lead to gum disease.`
  // },
  // {
  //   question: `Is Different Dog good for fussy dogs?`,
  //   answer: `Absolutely! We have 13 delicious recipes across 7 protein sources to ensure there is something for everyone to enjoy, even the fussiest of pups. Each dish oozes irresistible flavours and aromas, which have helped hundreds of picky eaters rediscover their love of mealtimes again. In fact, we’re so confident they’ll enjoy them that we offer an Empty Bowl Guarantee, which allows you to try our meals risk-free; if your dog doesn't love their first recipes, we'll give you your money back.`
  // }
]
