import { Dispatch } from 'redux'
import { fetchProducts } from '../apis/productsApi'
import { Action, ActionType } from '../types/productsType'

export const getProducts = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.GET_PRODUCTS_PENDING
    })
    try {
      const success = await fetchProducts()
      dispatch({
        type: ActionType.GET_PRODUCTS_SUCCESS,
        payload: {
          productListHash: success.productListHash,
          recipeList: success.recipes,
          addonList: success.addons
        }
      })
    } catch (_err) {
      dispatch({
        type: ActionType.GET_PRODUCTS_FAIL
      })
    }
  }
}
