export const stringFormatter = (s: string) => {
  let formattedString = s.toLowerCase()

  if (formattedString.includes(' ') || formattedString.includes('-')) {
    // Split by space

    let string = formattedString.split(' ')

    // Capitalize each string
    for (let i = 0; i < string.length; i++) {
      string[i] = string[i].charAt(0).toUpperCase() + string[i].slice(1)
    }

    // Join string back with space
    formattedString = string.join(' ')

    // Split by hyphen
    string = formattedString.split('-')

    // Capitalize each string
    for (let i = 0; i < string.length; i++) {
      string[i] = string[i].charAt(0).toUpperCase() + string[i].slice(1)
    }

    // Join string back with hyphen
    formattedString = string.join('-')
  } else {
    // string with no space or hyphen
    formattedString = formattedString.charAt(0).toUpperCase() + formattedString.slice(1)
  }

  return formattedString
}
