import { Cache } from 'aws-amplify'
import React, { Dispatch, SetStateAction, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateCouponCodeValidity, updateCurrCouponCode } from 'state/actions/dogAction'
import { updateCouponCode } from 'state/actions/parentAction'
import RedCross from '../../../images/redCross.svg'

interface CouponProps {
  defaultCouponCode: string | undefined
  couponCode: string | undefined
  setCouponCode: Dispatch<SetStateAction<string>>
  isValid: boolean
  isCouponValidationLoading: boolean
  labelClass?: string
  CouponChangeGA?: any
  validateFailGA?: any
  isRounded?: boolean
  page?: string
}

const CouponAlternative: React.FC<CouponProps> = ({
  defaultCouponCode,
  couponCode,
  setCouponCode,
  isValid,
  isCouponValidationLoading,
  labelClass,
  CouponChangeGA,
  validateFailGA,
  isRounded = false,
  page
}) => {
  const { currCouponCode } = useSelector((state: RootState) => state.dog)
  const dispatch = useDispatch()
  const [doesCouponValidated, setDoesCouponValidated] = useState(true)

  const handleChangeCouponCode = (code: string) => {
    setDoesCouponValidated(false)
    setCouponCode(code)
    if (code.toUpperCase() === defaultCouponCode) {
      dispatch(updateCouponCodeValidity(true))
      setDoesCouponValidated(true)
    } else {
      console.error('Change to new coupon code: CouponAlternative')
      dispatch(updateCouponCodeValidity(false))
    }
    if (!isValid) {
      if (typeof window.gtag !== 'undefined') {
        window.gtag('event', `${validateFailGA.event}`, {
          page: `${validateFailGA.page}`
        })
      }
    }
  }

  const handleValidateCouponCode = async () => {
    if (!isValid && doesCouponValidated) {
      revertToDefaultCoupon()
    } else {
      setDoesCouponValidated(false)
      if (typeof window.gtag !== 'undefined') {
        window.gtag('event', `${validateFailGA.event}`, {
          page: `${validateFailGA.page}`
        })
      }
      if (!isCouponValidationLoading && couponCode && currCouponCode !== couponCode) {
        console.error('Validating new coupon code on change: CouponAlternative')
        await dispatch(updateCouponCodeValidity('checkValidity', couponCode))
        setDoesCouponValidated(true)
        dispatch(updateCurrCouponCode(couponCode.toUpperCase() as string))
        dispatch(updateCouponCode(couponCode.toUpperCase() as string))
        Cache.setItem('coupon', couponCode.toUpperCase() as string)
      }
    }
  }

  const revertToDefaultCoupon = async () => {
    setCouponCode(defaultCouponCode as string)
    dispatch(updateCouponCodeValidity(true, defaultCouponCode))
    setDoesCouponValidated(true)
    dispatch(updateCurrCouponCode(defaultCouponCode as string))
    dispatch(updateCouponCode(defaultCouponCode as string))
    Cache.setItem('coupon', defaultCouponCode)
  }

  useEffect(() => {
    if (couponCode === defaultCouponCode) {
      handleValidateCouponCode()
    }
  }, [couponCode])

  useEffect(() => {
    if (!isValid && doesCouponValidated && couponCode && defaultCouponCode !== couponCode) {
      console.error(`Invalid coupon error: ${couponCode}`)
      if (typeof window.gtag !== 'undefined') {
        window.gtag('event', `invalid_coupon_error`, {
          event_category: 'Coupon',
          event_label: couponCode
        })
      }
    }
  }, [isValid, doesCouponValidated])

  return (
    <div className="w-full">
      <div className={`relative flex w-full ${isValid ? `flex-col` : `flex-row`}`}>
        <div className="w-full">
          <div className="relative flex flex-col w-full">
            <input
              className={`coupon peer block w-full self-center ${
                isRounded ? `rounded-full` : `rounded-md`
              } border border-gray-300 bg-white pt-6  focus:border-highlight focus:ring-highlight ${
                !isValid && doesCouponValidated ? '' : isValid && 'field-success-alternate'
              } ${!isValid && `mx-4`}  ${labelClass === 'hidden' ? 'ml-2 text-start' : 'text-center'} ${
                labelClass === 'hidden' && !isValid ? 'mr-2 ' : 'text-center'
              }
              ${page === 'checkoutAlternate' ? 'text-md' : ''}`}
              data-testid="coupon"
              id="coupon"
              name="coupon"
              type="text"
              value={couponCode ? couponCode : ''}
              onChange={e => handleChangeCouponCode(e.target.value.replaceAll(' ', ''))}
              onBlur={handleValidateCouponCode}
              autoComplete="off"
            />
            {!isValid && doesCouponValidated && (
              <button
                type="button"
                className="absolute right-2 top-1/2 transform -translate-y-1/2"
                onClick={() => setCouponCode('')}
              >
                <img width={15} src={RedCross} alt="Red cross" />
              </button>
            )}
            {!isValid && !doesCouponValidated && (
              <button
                data-testid="validateCoupon"
                id="validateCoupon"
                type="button"
                className={`absolute right-2 top-1/2 transform -translate-y-1/2 px-3 py-1 rounded ${
                  page === 'pupParent' ? 'btn-cta !text-sm' : 'btn-cta'
                } focus:outline-none`}
                onClick={handleValidateCouponCode}
              >
                {isCouponValidationLoading ? (
                  <div className="align-center flex justify-center">
                    <div className="border-white-900 mr-2 h-5 w-5 animate-spin rounded-full border-b-2"></div>
                    <p>Checking</p>
                  </div>
                ) : (
                  'Apply'
                )}
              </button>
            )}
            {labelClass === 'hidden' && (
              <label
                className={`absolute top-4 flex transform cursor-text appearance-none justify-start duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                  couponCode ? 'ml-0 -translate-y-3 scale-75' : 'ml-2'
                } `}
                htmlFor="coupon"
              >
                Discount code
              </label>
            )}
            {labelClass !== 'hidden' && (
              <label
                className={`absolute top-0 inline-flex h-full w-full transform cursor-text appearance-none items-center justify-center duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75
               ${couponCode ? 'ml-0 -translate-y-3 scale-75' : 'ml-0'} `}
                htmlFor="coupon"
              >
                Discount code
              </label>
            )}
          </div>
        </div>
      </div>
      {!isValid && doesCouponValidated && (
        <>
          <div className="flex flex-row justify-around w-full items-center mt-2">
            <div data-testid="[invalidCouponError]" className="p-2 pb-0 text-xs text-red-500">
              Invalid coupon code
            </div>
            <button
              type="button"
              className="px-3 py-1 rounded btn-primary text-xs focus:outline-none"
              onClick={revertToDefaultCoupon}
            >
              Apply Default Coupon
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default CouponAlternative
