import React from 'react'

const PrivacyPolicy: React.FC = () => {
  return (
    <>
      <h1 className="mb-4 p-4 text-center text-3xl tracking-tight text-gray-900 sm:text-5xl">Privacy Policy</h1>
      <p>
        Cookie Policy
        <br />
        <br />A cookie is a small file which is placed on your computer when you visit our site.
        <br />
        <br />
        Cookies help us analyse traffic to our website, make the website function for you and help us to make
        improvements for our customers.
        <br />
        <br />
        We use Necessary Cookies to make our site work. We would also like to use analytics and marketing cookies (Not
        Necessary) that help us make improvements by measuring how you use the site and enables us to present relevant
        content based on your browsing history.
        <br />
        <br />
        Necessary Cookies: these enable core functionality such as security, network management and accessibility. You
        can disable (Reject) all Cookies by changing your browser settings, but this may mean that you won’t get the
        full functionality of our website.
        <br />
        <br />
        Analytical Cookies: we would like to use these to help in improving our website through the collection and
        reporting of how you use our website. The use of these will not directly identify you as an individual but will
        collect and use data that is considered personal to you. (Eg. By uniquely allocated ID and/or your IP address)
        <br />
        <br />
        Marketing Cookies: we would like to use these to track your browsing across websites; which will help us provide
        you with relevant advertisements and content.
        <br />
        <br />
        If you choose to ‘Accept’ all cookies on our site, then you will be permitting the use of analytical and
        marketing cookies but you can choose to set your Cookie preferences and not accept these also. It is important
        to understand that accepting all cookies will also allow access to the information gained by cookies to other
        third parties and as indicated in the below table.
        <br />
        <br />
        You are also able to change the settings in your browser to not accept ‘Tracking’ type cookies and there are
        functions which will allow you to delete all Cookies downloaded by any website you visit.
        <br />
        <br />
        The following Cookies are in use on our website and will remain on your computer for the duration indicated:
        <br />
        <br />
        Cookie Name Use Expiry
        <br />
        <br />
        Necessary Cookies for full functionality of website
        <br />
        <br />
        _cfruid
        <br />
        <br />
        This cookie is a part of the services provided by Cloudflare - Including load-balancing, deliverance of web site
        content and serving DNS connection for website operators. Session
        <br />
        <br />
        __zlcstore
        <br />
        <br />
        This cookie is necessary for the chat-box function on the website to function . Persistent
        <br />
        <br />
        AWSALBCORS Registers which server-cluster is serving the visitor. This is used in context with load balancing,
        in order to optimize user experience. 6 Days
        <br />
        <br />
        mm_id Used by the website to protect against fraud in relation to its referral system. 5 years
        <br />
        <br />
        test_cookie Used to check if the user's browser supports cookies. 1 Day
        <br />
        <br />
        ZD-suid Unique ID that identifies the user's session . Persistent
        <br />
        <br />
        ZD-store Registers whether the self-service-assistant Zendesk Answer Bot has been displayed to the website user
        Persistent
        <br />
        <br />
        Analytical Cookies (Not Necessary)
        <br />
        <br />
        _ga
        <br />
        <br />
        _ga# Google Analytics: Registers a unique ID that is used to generate statistical data on how the visitor uses
        the website, number of visits and dates 2 Years
        <br />
        <br />
        _gat
        <br />
        <br />
        Google Analytics: Used to throttle request rate. 1 Day
        <br />
        <br />
        _gid
        <br />
        <br />
        Google Analytics: Registers a unique ID that is used to generate statistical data on how the visitor uses the
        website. 1 Day
        <br />
        <br />
        collect Google Analytics: Used to send data to Google Analytics about the visitor's device and behaviour. Tracks
        the visitor across devices and marketing channels Session
        <br />
        <br />
        _hjAbsoluteSessionI
        <br />
        <br />
        nProgress Hotjar Analytics: This cookie is used to count how many times a website has been visited by different
        visitors - this is done by assigning the visitor an ID, so the visitor does not get registered twice. 1 Day
        <br />
        <br />
        _hjCachedUserAttrib
        <br />
        <br />
        utes Hotjar Analytics: Used in context with the website’s user feedback functionality. Determines whether the
        user has been shown feedback messages. Session
        <br />
        <br />
        _hjFirstSeen Hotjar Analytics: This cookie is used to determine if the visitor has visited the website before,
        or if it is a new visitor on the website. 1 Day
        <br />
        <br />
        _hjid Hotjar Analytics: Sets a unique ID for the session . This allows the website to obtain data on visitor
        behaviour for statistical purposes. Persistent
        <br />
        <br />
        _hjIncludedInPagevi
        <br />
        <br />
        ewSample Hotjar Analytics: Used to detect whether the user navigation and interactions are included in the
        website’s data analytics. 1 Day
        <br />
        <br />
        _hjIncludedInSession
        <br />
        <br />
        Sample Hotjar Analytics: Registers data on visitors' website-behaviour. This is used for in tern al analysis and
        website optimization. 1 Day
        <br />
        <br />
        _hjRecordingLastActivity
        <br />
        <br />
        _hjTLDTest
        <br />
        <br />
        _hjViewportId Hotjar Analytics: Sets a unique ID for the session . This allows the website to obtain data on
        visitor behaviour for statistical purposes. Session
        <br />
        <br />
        _hjUserAttributesHa
        <br />
        <br />
        sh Hotjar Analytics: Identifies if the cookie data needs to be updated in the visitor's browser. 1 Day
        <br />
        <br />
        ZD-buid ZD Assets Analytics: Sets unique ID that identifies the user on recurring visits. Persistent
        <br />
        <br />
        Marketing Cookies (Not Necessary)
        <br />
        <br />
        __zlcmid ZD Assets:Preserves users states across page requests. 1 Year
        <br />
        <br />
        _fbp
        <br />
        <br />
        tr Facebook: Used by Facebook to deliver a series of advertisement products. 3 Months
        <br />
        <br />
        _hjRecordingEnabled Hotjar: This cookie is used to identify the visitor and optimize ad-relevance by collecting
        visitor data from multiple websites – this exchange of visitor data is normally provided by a third-party
        data-centre or ad-exchange. Session
        <br />
        <br />
        _mailmunch_visitor_
        <br />
        <br />
        id
        <br />
        <br />
        mailmunch_second_
        <br />
        <br />
        pageview
        <br />
        <br />
        Mailmunch: Tracks the individual sessions on the website, allowing the website to compile statistical data from
        multiple visits. This data can also be used to create leads for marketing purposes. Persistent
        <br />
        <br />
        _uetsid
        <br />
        <br />
        _uetsid_exp
        <br />
        <br />
        _uetvid
        <br />
        <br />
        _uetvid_exp Bing: Used to track visitors on multiple websites, in order to present relevant advertisement based
        on the visitor's preferences. Persistent
        <br />
        <br />
        IDE
        <br />
        <br />
        Google Doubleclick: Used by Google DoubleClick to register and report the website user's action s after viewing
        or clicking one 1 Year
        <br />
        <br />
        of the advertiser's ads with the purpose of measuring the efficacy of an ad and to present targeted ads to the
        user.
        <br />
        <br />
        mm_allocation Mention Me: Used by third-party marketing platform to facilitate A/B testing on different offers
        shown to the user. 5 Years
        <br />
        <br />
        MUID Bing: Used widely by Microsoft as a unique user ID. The cookie enables user tracking by synchronising the
        ID across many Microsoft domains. 1 Year
        <br />
        <br />
        pagead/1p-user-
        <br />
        <br />
        list/# Google: Tracks if the user has shown interest in specific products or events across multiple websites and
        detects how the user navigates between sites. This is used for measurement of advertisement efforts an d
        facilitates payment of referral-fees between websites. Session
        <br />
        <br />
        zte# ZD Assets: : Saves a Zopim Live Chat ID that recognises a device between visits during a chat session .
      </p>
    </>
  )
}

export default PrivacyPolicy
