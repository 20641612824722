export const sliderData = [
  {
    image: 'EmptyBowl',
    title: 'Empty bowl guarantee',
    description:
      "Try our food risk-free with our Empty Bowl guarantee; if your dog doesn't like our food on their first delivery, we'll refund you."
  },
  {
    image: 'Pause',
    title: 'Amend, pause or cancel anytime',
    description:
      "You're in control; with no fixed-term contracts and a user-friendly online account, you can amend, pause or cancel your plan anytime."
  },
  {
    image: 'Vet',
    title: 'Vet support available',
    description:
      'Worried about your dog? As a customer, you can book a free consultation with our in-house vet or nutritionist any time you need it.'
  },
  {
    image: 'Cook',
    title: 'Hand cooked',
    description:
      'All our meals are hand-cooked in a giant pan at a low temperature to help preserve the natural goodness in every single ingredient.'
  },
  {
    image: 'Fussy',
    title: 'Fussy dog approved',
    description:
      'Our delicious flavours and irresistible aromas have helped thousands of fussy pups rediscover their appetite and enjoy mealtimes again.'
  },
  {
    image: 'Ingredients',
    title: 'Nutritious ingredients',
    description:
      'We cook with only the highest quality meat and fish, carefully paired with fruit and vegetables, just like the ones you shop for at the supermarket.'
  },
  {
    image: 'Delivery',
    title: 'Delivered to your door',
    description:
      'Choose eight, four or two-week delivery slots, then sit back and relax as our Carbon Neutral couriers bring your recipes to you.'
  }
]
