import RemoveDogSignupModal from 'components/ui/modal/RemoveDogSignupModal'
import StickyFooter from 'components/ui/sticky-footer/StickyFooter'
import dayjs from 'dayjs'
import { Field } from 'formik'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Select, { components } from 'react-select'
import Api from 'state/apis/api'
import { DogsCtx } from '.'
import { formValidationErrors, infoBoxes, months, pageSections, puppyPageSections } from './data'
import './dogAccordion.css'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface DogAccordionProps {
  formikProps: {
    errors: any
    touched: any
    setFieldValue: any
    values: any
  }
  maxItems: number
  overrideFirstName?: string
  overrideFirstBreed?: string
}

const DogAccordion: React.FC<DogAccordionProps> = ({
  formikProps,
  maxItems,
  overrideFirstName,
  overrideFirstBreed
}) => {
  const {
    loading
  }: {
    loading: boolean
  } = useSelector((state: RootState) => state.dog)
  const [years, setYears] = useState<number[]>([])
  // Handle the page state
  const [pageShown, setPageShown] = useState('name')
  const [index, setIndex] = useState(0)
  const [dogName, setDogName] = useState(formikProps.values[`name${index}`])

  const [backButtonVisible, setBackButtonVisible] = useState(true)
  const [mobileScroll, setMobileScroll] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [showInfoBox, setShowInfoBox] = useState('')
  const [sendConsoleErrorToCloudWatch, setSendConsoleErrorToCloudWatch] = useState(false)
  const [breeds, setBreeds] = useState([])
  const [sortedBreeds, setSortedBreeds] = useState([])

  // Handles which dog is selected when multiple dogs
  const [dogNameCount, setDogNameCount] = useState(0)
  const [dogNameArray, setDogNameArray] = useState<Array<{ index: number; name: string }>>([])
  const [addNewDog, setAddNewDog] = useState(false)

  const [removeDogModalShow, setRemoveDogModalShow] = useState(false)
  const [removeDogConfirm, setRemoveDogConfirm] = useState(false)
  const [removeDogIndex, setRemoveDogIndex] = useState(0)

  const [formErrors, setFormErrors] = useState<any>({})
  const dogCTX = useContext(DogsCtx)

  let typingTimer: any
  const doneTypingInterval = 1000

  const todayMinus1Year = dayjs().subtract(1, 'year')
  const todayDate = dayjs().format('DD/MM/YYYY')
  const currentDate = todayDate.split('/')
  const nextDog =
    dogNameCount + 1 === 2
      ? '2nd dog'
      : dogNameCount + 1 === 3
        ? '3rd dog'
        : dogNameCount + 1 === 4
          ? '4th dog'
          : dogNameCount + 1 === 5
            ? '5th dog'
            : ''

  const handleWindowSizeChange = () => {
    if (window.innerHeight < 500) {
      setMobileScroll(true)
    }
    if (window.innerHeight < 700) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    handleWindowSizeChange()
    window.addEventListener('resize', handleWindowSizeChange)

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  useEffect(() => {
    setIndex(1)
  }, [])

  useEffect(() => {
    const yearsArray = []
    for (let year = new Date().getFullYear(); year >= 2000; year--) {
      yearsArray.push(year)
    }
    setYears(yearsArray)
  }, [])

  useEffect(() => {
    // Handles if back button is shown
    if (pageShown === 'name') {
      setBackButtonVisible(true)
    } else {
      setBackButtonVisible(false)
    }
  }, [pageShown])

  useEffect(() => {
    // Handles the dog list for multiple dogs
    if (!addNewDog) {
      const dogIndexList = []
      for (let i = 1; i <= maxItems; i++) {
        dogIndexList.push({ index: i, name: formikProps.values[`name${i}`] })
      }
      setDogNameArray(dogIndexList)
      if (dogNameArray.findIndex(item => item.name === '') > 0) {
        setDogNameCount(dogNameArray.findIndex(item => item.name === ''))
      }
    }
    if (formikProps.values[`name${index}`] !== dogName) {
      setDogName(formikProps.values['name1'])
    }
  }, [formikProps.values, index])

  useEffect(() => {
    // Updates for next dog
    if (addNewDog) {
      const updatedArray = [...dogNameArray]
      updatedArray[dogNameCount].name = nextDog
      setDogNameArray(updatedArray)
      setDogNameCount(dogNameArray.findIndex(item => item.name.includes('dog')) + 1)
      setAddNewDog(false)
    }
  }, [addNewDog])

  useEffect(() => {
    if (sendConsoleErrorToCloudWatch && Object.keys(formikProps.errors).length > 0) {
      console.error(JSON.stringify(formikProps.errors))
    }
    // Send individual section errors
    if (sendConsoleErrorToCloudWatch && Object.keys(formErrors).length > 0) {
      console.error(JSON.stringify(formErrors))
    }
    setSendConsoleErrorToCloudWatch(false)
  }, [sendConsoleErrorToCloudWatch])

  useEffect(() => {
    // Adds dog name if entered on homepage
    if (overrideFirstName) {
      formikProps.setFieldValue([`name1`], overrideFirstName)
      let dogName = overrideFirstName
      if (!/^[A-Z]/.test(dogName)) {
        dogName = dogName.charAt(0).toUpperCase() + dogName.slice(1)
        formikProps.setFieldValue([`name1`], dogName)
      }
      if (typeof window.gtag !== 'undefined') {
        window.gtag('event', 'entered_dog_name', {
          page: 'sign_up_dog_details_new',
          dog_name: dogName
        })
        //TODO:DS Duplication to test category/label (remove above if working)
        window.gtag('event', 'entered_dog_name', {
          page: 'sign_up_dog_details_new',
          dog_name: dogName,
          event_category: 'entered_dog_name',
          event_label: 'entered_dog_name'
        })
      }
      setDogName(dogName)
      handleNext()
    }
  }, [overrideFirstName])

  useEffect(() => {
    // Adds dog breed if entered on homepage
    if (overrideFirstBreed) {
      formikProps.setFieldValue([`breed1`], overrideFirstBreed)
      let dogBreed = overrideFirstBreed
      if (!/^[A-Z]/.test(dogBreed)) {
        formikProps.setFieldValue([`breed1`], [dogBreed])
      }
      if (typeof window.gtag !== 'undefined') {
        window.gtag('event', 'entered_dog_breed', {
          page: 'sign_up_dog_details_new',
          dog_breed: dogBreed
        })
      }
    }
  }, [overrideFirstBreed])

  useEffect(() => {
    // Handles removing dog
    if (removeDogConfirm) {
      setRemoveDogConfirm(false)
      if (removeDogIndex > maxItems) return

      for (let i = removeDogIndex; i <= maxItems; i++) {
        formikProps.setFieldValue([`name${i}`], formikProps.values[`name${i + 1}`] || '')
        formikProps.setFieldValue([`dobYear${i}`], formikProps.values[`dobYear${i + 1}`] || '')
        formikProps.setFieldValue([`dobMonth${i}`], formikProps.values[`dobMonth${i + 1}`] || undefined)
        formikProps.setFieldValue([`gender${i}`], formikProps.values[`gender${i + 1}`] || '')
        formikProps.setFieldValue([`breed${i}`], formikProps.values[`breed${i + 1}`] || undefined)
        formikProps.setFieldValue([`neutered${i}`], formikProps.values[`neutered${i + 1}`] || '')
        formikProps.setFieldValue([`weight${i}`], formikProps.values[`weight${i + 1}`] || '')
        formikProps.setFieldValue([`physique${i}`], formikProps.values[`physique${i + 1}`] || '')
        formikProps.setFieldValue([`exercise${i}`], formikProps.values[`exercise${i + 1}`] || '')
        formikProps.setFieldValue(
          [`medicalConditionOrAllergies${i}`],
          formikProps.values[`medicalConditionOrAllergies${i + 1}`] || ''
        )
        formikProps.setFieldValue([`fussy${i}`], formikProps.values[`fussy${i + 1}`] || '')
        formikProps.setFieldValue([`unknownBreed${i}`], formikProps.values[`unknownBreed${i + 1}`] || undefined)
      }
      setIndex(1)
      setPageShown('next')
      handleProgressBar(1)
      setDogName(formikProps.values['name1'])
    }
  }, [removeDogConfirm])

  useEffect(() => {
    if (dogNameArray.filter(dogName => dogName.name !== '').length !== dogNameCount)
      setDogNameCount(dogNameArray.filter(dogName => dogName.name !== '').length)
  }, [dogNameArray])

  useEffect(() => {
    callBreeds()
  }, [])

  const callBreeds = () => {
    const url = '/v1/public/signup/breeds'
    Api.get('wwwREST', url, {})
      .then(({ breedsList }) => {
        setBreeds(breedsList)
        setSortedBreeds(breedsList)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const getBreed = () => {
    return breeds.filter((breed: any) => formikProps?.values?.[`breed${index}`].includes(breed.value))
  }
  const listBreeds = (sort = '') => {
    if (!sort) {
      setSortedBreeds(breeds)
      return
    }
    // Sort the breeds to put the user typed breeds at the top
    setSortedBreeds(
      [...breeds].sort((a, b) => {
        if (a.label.toLowerCase().startsWith(sort.toLowerCase())) {
          return -1
        } else if (b.label.toLowerCase().startsWith(sort.toLowerCase())) {
          return 1
        } else {
          return a.label.localeCompare(b.label)
        }
      })
    )
  }

  const handleNext = () => {
    let pageSectionsArray = pageSections
    if (formikProps.values[`exercise${index}`] === 'GROWINGPUPPY') {
      pageSectionsArray = puppyPageSections
      if (!formikProps.values[`dobMonth${index}`]) {
        setFormErrors({ [`dobMonth${index}`]: 'Month of birth is required' })
        return
      }
    }
    for (let i = 0; i < pageSectionsArray.length - 1; i++) {
      if (pageSectionsArray[i].value === pageShown) {
        setPageShown(pageSectionsArray[i + 1].value)
        handleProgressBar(pageSectionsArray[i + 1].section)
      }
    }
  }

  const handleBack = () => {
    let pageSectionsArray = pageSections
    if (formikProps.values[`exercise${index}`] === 'GROWINGPUPPY') {
      pageSectionsArray = puppyPageSections
    }
    for (let i = 1; i < pageSectionsArray.length; i++) {
      if (pageSectionsArray[i].value === pageShown) {
        setPageShown(pageSectionsArray[i - 1].value)
        handleProgressBar(pageSectionsArray[i - 1].section)
      }
    }
  }

  const handleProgressBar = (i: number) => {
    let numberOfPages =
      formikProps.values[`exercise${index}`] !== 'GROWINGPUPPY' ? pageSections?.length : puppyPageSections?.length
    let scrolled = (i / numberOfPages) * 100
    document.getElementById('progressBar').style.width = scrolled + '%'
    document.getElementById('progressBarMobile').style.width = scrolled + '%'
  }

  const removeDog = (removedIndex: number) => {
    setRemoveDogIndex(removedIndex)
    setRemoveDogModalShow(true)
  }

  const showHeadline = () => {
    if (pageShown === 'name' && index === 1 && dogNameCount === 0) {
      return true
    }
    if (pageShown === 'name' && index === 1 && dogNameCount === 1) {
      return true
    }
    return false
  }

  const handleSubmitForm = (formikProps: any, validateType: string, dogIndex: number) => {
    // Hacky way to validate form and scroll to errors
    dogCTX.validateForm()
    const errors: any = {}
    // Get missing fields for dogs
    Object.entries(formikProps.values).forEach(field => {
      // if (field[0].slice(0, -1) === 'breed' && field[1]) {
      //   if (Array.isArray(field[1]) && field[1].length === 0) {
      //     errors[field[0]] = 'Please select a breed'
      //   }
      // }
      if (field[0].slice(0, -1) === 'weight' && field[1]) {
        if (typeof field[1] === 'number' && field[1] >= 75) {
          errors[field[0]] = 'Weight must be below 75kg'
        }
      }
      if (
        field[0].includes('dobMonth') &&
        !formikProps.values[`dobMonth${index}`] &&
        formikProps.values[`dobYear${index}`] >= todayMinus1Year.year()
      ) {
        errors[`dobMonth${index}`] = 'Month of birth is required'
      }
      if (field[0].includes(index.toString()) && !field[0].includes('dobMonth') && !field[1]) {
        const errorSection = formValidationErrors.find(obj => obj.key === field[0].slice(0, -1))
        if (errorSection) {
          errors[field[0]] = errorSection.error
        }
      }
    })
    formikProps.errors = errors
    setFormErrors(errors)
    if (Object.keys(formikProps.errors).length > 0) {
      const errors = formValidationErrors
        .map(({ key }) => {
          const matchingError = Object.entries(formikProps.errors).find(([errorKey]) => errorKey.includes(key))
          return matchingError ? matchingError : null
        })
        .filter(error => error !== null)

      // Handle dobMonth, scroll back to dobYear
      if (errors && errors[0][0]?.slice(0, -1) === 'dobMonth') {
        const errorSection = pageSections.find(obj => obj.value === 'dobYear')
        if (errorSection) {
          setPageShown(`${errorSection.value}`)
          handleProgressBar(errorSection.section)
        }
      }
      const errorSection = errors.length > 0 ? pageSections.find(obj => obj.value === errors[0][0].slice(0, -1)) : null
      if (errorSection) {
        setPageShown(`${errorSection.value}`)
        handleProgressBar(errorSection.section)
      }
    } else {
      if (validateType === 'addDog') {
        setPageShown('name')
        handleProgressBar(1)
        setIndex(dogNameCount + 1)
        setAddNewDog(true)
      } else if (validateType === 'changeDog') {
        setPageShown('name')
        handleProgressBar(1)
        setIndex(dogIndex)
        setDogName(formikProps.values[`name${dogIndex}`])
      } else {
        formikProps.handleSubmit(formikProps.values)
      }
    }
  }

  const DropdownIndicator = (props: ElementConfig<typeof components.DropdownIndicator>) => {
    return (
      <components.DropdownIndicator {...props}>
        <StaticImage className="w-5 h-full" src="../../../images/checkout/cheveronDown.svg" alt="Dropdown" />
      </components.DropdownIndicator>
    )
  }

  const renderProgressBarText = () => {
    if (pageShown === 'name') {
      return 'The best nutrition is tailored'
    }
    if (pageShown === 'name' && index >= dogNameCount && !formikProps.values[`name${index}`]) {
      return "Your dog's details"
    }
    if (pageShown === 'name' && index > dogNameCount) {
      return `Your ${nextDog}'s details`
    }
    if (pageShown === 'next') {
      return "You're almost there!"
    }
    let title = dogName ? `${dogName}'s details` : "Your dog's details"
    if (window.innerWidth <= 450) {
      title = dogName && dogName.length <= 20 ? `${dogName}'s details` : "Your dog's details"
    }
    if (window.innerWidth <= 395) {
      title = dogName && dogName.length <= 15 ? `${dogName}'s details` : "Your dog's details"
    }
    return title
  }

  const renderDogNameButtons = () => {
    return (
      <div className="mb-0 whitespace-nowrap w-full overflow-y-hidden overflow-x-auto">
        <ul className="mt-4 mx-auto w-full">
          {dogNameArray.map((dog: any, i: number) => (
            <li key={i} className="mx-1 inline-flex flex-1 items-center justify-center md:flex-initial">
              {dog.name && (
                <div className="flex flex-col">
                  <button
                    className={`inline-flex justify-center rounded-full border-2 border-[#6BA2B3] bg-white px-2 font-sans text-base font-medium ${
                      index === dog.index ? 'bg-[#D7E6EA] py-[0.4rem] px-4 font-bold' : 'px-2  py-1'
                    }`}
                    onClick={() => {
                      handleSubmitForm(formikProps, 'changeDog', dog.index)
                    }}
                  >
                    {dog.name}{' '}
                    {index !== dog.index && (
                      <a
                        onClick={() => {
                          handleSubmitForm(formikProps, 'changeDog', dog.index)
                        }}
                      >
                        <StaticImage
                          className="relative ml-2 w-6"
                          src={'../../../../images/dog-details/dog-details-check.svg'}
                          alt="Check"
                        />
                      </a>
                    )}
                  </button>
                  <div className="flex flex-row justify-center pt-1">
                    <a
                      className={`${dog.index === index ? '' : 'hidden'} flex items-center`}
                      onClick={() => removeDog(dog.index)}
                    >
                      <StaticImage
                        className={`relative mr-1 w-[0.65rem] ${dog.index === index ? '' : 'hidden'}`}
                        src={'../../../../images/dog-details/dog-details-remove.svg'}
                        alt="Remove"
                      />
                    </a>
                    <button
                      type="button"
                      className={`${
                        dog.index === index ? '' : 'hidden'
                      } text-sm font-sans justify-center inline-flex hover:underline`}
                      onClick={() => removeDog(dog.index)}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    )
  }

  // Info boxes
  const renderInfoSection = (section: string) => {
    const info = infoBoxes.find((e: any) => e.section === section)
    const linkBoxes = ['Physique', 'MedicalConditionOrAllergies']
    if (info) {
      return (
        <>
          {/* Mobile view */}
          {isMobile && (
            <div
              onClick={() => setShowInfoBox(info.section)}
              className={`flex info-box-mobile ${info.showOnMobile ? '' : 'info-box-mobile-image'} 
         fixed bottom-20 md:bottom-28 md:left-4 flex justify-center md:justify-start w-auto 
        `}
            >
              <div
                onClick={() => setShowInfoBox(info.section)}
                className={`w-11/12 sm:w-full mx-auto md:mx-0 ${
                  info.section === 'Weight' || info.section === 'MedicalConditionOrAllergies'
                    ? 'info-box-mobile-extra'
                    : ''
                } `}
              >
                <div className="pl-8 font-third text-3xl -rotate-3 transform">{info.headline}</div>
                <div className={`bg-[#F8F2EC] flex items-center -mt-3 mr-2 lg:mr-0 rounded-2xl`}>
                  <p className={`font-sans font-medium text-left text-xs md:text-sm text-black p-3`}>{info.text}</p>
                  <a
                    className="absolute top-4 right-4 h-5 w-5 text-primary"
                    onClick={() => setShowInfoBox(info.section)}
                  >
                    <StaticImage
                      className="w-full"
                      src={'../../../../images/dog-details/dog-details-remove-with-border.svg'}
                      alt="Remove"
                    />
                  </a>
                </div>
              </div>
            </div>
          )}
          {/* Desktop view */}
          {!isMobile && (
            <div
              className={`flex fixed bottom-24 md:bottom-28 md:left-4 justify-center md:justify-start w-auto 
          ${
            info.section === 'MedicalConditionOrAllergies' || info.section === 'Physique'
              ? 'lg:max-w-[50%]'
              : 'lg:max-w-[33%]'
          }`}
            >
              {info.image && <>{renderInfoImage(info.image, info.imageAlt)}</>}
              <div className="w-11/12 sm:w-full mx-auto md:mx-0">
                <div className="pl-8 font-third text-3xl -rotate-3 transform">{info.headline}</div>
                <div
                  className={`bg-[#F8F2EC] flex items-center -mt-3 mr-2 lg:mr-0 rounded-2xl ${
                    info.image ? 'max-h-[7.5rem]' : ''
                  }`}
                >
                  {!linkBoxes.some(page => info.section.includes(page)) && (
                    <p
                      className={`font-sans font-medium text-left text-xs md:text-sm text-black ${
                        info.image ? 'ml-1' : ''
                      } p-8`}
                    >
                      {info.text}
                    </p>
                  )}
                  <a
                    className="absolute top-3 right-0 lg:-right-2 h-6 w-6  text-primary"
                    onClick={() => setShowInfoBox(info.section)}
                  >
                    <StaticImage
                      className="w-full"
                      src={'../../../../images/dog-details/dog-details-remove-with-border.svg'}
                      alt="Remove"
                    />
                  </a>
                  {info.section === 'Physique' && (
                    <div
                      className={`font-sans font-medium text-left text-xs md:text-sm text-black ${
                        info.image ? 'ml-1' : ''
                      } p-8`}
                    >
                      {info.text} Email the team at{' '}
                      <a href="mailto:feedme@differentdog.com" className="font-bold">
                        feedme@differentdog.com
                      </a>{' '}
                      to chat with me.
                      <p className="font-serif">Bianca, in-house nutritionist</p>
                    </div>
                  )}
                  {info.section === 'MedicalConditionOrAllergies' && (
                    <div
                      className={`font-sans font-medium text-left text-xs md:text-sm text-black ${
                        info.image ? 'ml-1' : ''
                      } p-8`}
                    >
                      {' '}
                      {info.text} email me at{' '}
                      <a href="mailto:feedme@differentdog.com" className="font-bold">
                        feedme@differentdog.com
                      </a>{' '}
                      and I’ll recommend the most beneficial recipes for your four-legged friend.{' '}
                      <p className="font-serif">Alison, in-house vet</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )
    }
  }

  // Info box images
  const renderInfoImage = (image: string, imageAlt: string) => {
    switch (image) {
      case 'Name':
        return (
          <StaticImage
            objectFit="contain"
            className="info-box-mobile-image w-full max-w-[110px] md:max-w-[118px] -mr-7"
            src={'../../../../images/dog-details/info-section/info-section-name.png'}
            alt={imageAlt}
          />
        )
      case 'Gender':
        return (
          <StaticImage
            objectFit="contain"
            className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
            src={'../../../../images/dog-details/info-section/info-section-gender.png'}
            alt={imageAlt}
          />
        )
      case 'Breed':
        return (
          <StaticImage
            objectFit="contain"
            className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
            src={'../../../../images/dog-details/info-section/info-section-breed.png'}
            alt={imageAlt}
          />
        )
      case 'Neutered':
        return (
          <StaticImage
            objectFit="contain"
            className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
            src={'../../../../images/dog-details/info-section/info-section-neutered.png'}
            alt={imageAlt}
          />
        )
      case 'DOB':
        return (
          <StaticImage
            objectFit="contain"
            className="w-full max-w-[110px] md:max-w-[118px] -mr-7 "
            src={'../../../../images/dog-details/info-section/info-section-dob.png'}
            alt={imageAlt}
          />
        )
      case 'Weight':
        return (
          <StaticImage
            objectFit="contain"
            className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
            src={'../../../../images/dog-details/info-section/info-section-weight.png'}
            alt={imageAlt}
          />
        )
      case 'Physique':
        return (
          <StaticImage
            objectFit="contain"
            className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
            src={'../../../../images/dog-details/info-section/info-section-physique.png'}
            alt={imageAlt}
          />
        )
      case 'Exercise':
        return (
          <StaticImage
            objectFit="contain"
            className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
            src={'../../../../images/dog-details/info-section/info-section-exercise.png'}
            alt={imageAlt}
          />
        )
      case 'MedicalConditionOrAllergies':
        return (
          <StaticImage
            objectFit="contain"
            className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
            src={'../../../../images/dog-details/info-section/info-section-medical.png'}
            alt={imageAlt}
          />
        )
    }
  }

  return (
    <>
      <div className="z-index-1 fixed md:h-20 w-full bg-white">
        {/* Desktop view progress bar */}
        <div
          id="progressBar"
          className={`transition-animation absolute z-20 top-0 left-0 right-0 h-8 w-[0%]
          bg-[#6BA2B3] hidden md:block`}
        />
        <div className={`z-10 absolute top-0 left-0 right-0 h-8 w-full bg-[#B0CDD5] hidden md:block`} />
        <div className={`dog-details-progress-bar hidden md:block absolute mt-4 h-12 z-40 `} />

        {/* Mobile view progress bar */}
        <div
          id="progressBarMobile"
          className={`transition-animation absolute z-20 top-0 left-0 right-0 h-8 w-[0%]
          bg-[#6BA2B3] block md:hidden`}
        />
        <div className={`z-10 absolute top-0 left-0 right-0 h-8 w-full bg-[#B0CDD5] block md:hidden`} />
        <div className={`dog-details-progress-bar-mobile block md:hidden absolute mt-6 h-8 w-full z-40 `} />
        <div className={`relative z-50 items-center`}>
          <p
            data-testid="createYourPlanText"
            className={`pb-0 lg:pt-20 text-center text-2xl tracking-tight sm:text-4xl font-serif ${
              dogNameCount > 1 ? 'pt-12' : 'pt-[3.5rem] md:pt-16'
            }`}
          >
            {renderProgressBarText()}
            {dogNameCount > 1 && renderDogNameButtons()}
          </p>
          {showHeadline() && (
            <>
              <p className=" block w-full lg:w-1/2 px-2 md:px-0 mx-auto font-sans font-medium text-center text-xs md:text-sm text-black">
                Let’s create the right meal plan and recipes for your dogs needs.
              </p>
              <p className="block w-full lg:w-1/2 px-2 md:px-0 mx-auto font-sans font-medium text-center text-xs md:text-sm text-black">
                This will only take 2 minutes.
              </p>
            </>
          )}
        </div>
      </div>
      {/* Dog name section */}
      {pageShown === 'name' && (
        <div id="name" className="question-show h-[72vh] flex items-center justify-center bg-white">
          <div
            className={`justify-start md:relative ${
              mobileScroll ? '!top-40 h-48 overscroll-auto relative' : 'fixed h-[72vh]'
            } lg:pt-20 flex w-full flex-col items-center my-auto md:justify-center ${
              dogNameCount > 1 ? 'top-[16rem] md:-top-4' : 'top-[17rem] md:top-0'
            }`}
          >
            {/* Mobile View */}
            <span className="block lg:hidden sans font-medium mb-4 text-center text-2xl md:text-3xl">
              Who are we feeding?
            </span>
            {/* Desktop View */}
            <span className="hidden lg:block sans font-medium mb-4 text-center text-2xl md:text-3xl">
              Who are we feeding?
            </span>
            <label htmlFor="first-name" className="mx-auto mb-2 flex w-full flex-col">
              <Field
                data-testid="dogName"
                type="text"
                id={`name${index}`}
                name={`name${index}`}
                className="text-gray block w-2/3 self-center rounded-full border-2 border-gray-200 bg-white py-4 px-6 text-center focus:border-highlight focus:ring-highlight lg:w-2/6"
                placeholder="Enter your dog's name"
                onChange={(e: any) => {
                  setFormErrors({})
                  formikProps.setTouched({ [`name${index}`]: true })
                  clearTimeout(typingTimer)
                  formikProps.setFieldValue([`name${index}`], e.target.value)
                  typingTimer = setTimeout(() => {
                    let dogName = e.target.value
                    if (!/^[A-Z]/.test(dogName)) {
                      dogName = dogName.charAt(0).toUpperCase() + dogName.slice(1)
                      formikProps.setFieldValue([`name${index}`], dogName)
                    }
                    setDogName(dogName)
                  }, doneTypingInterval)
                }}
                onBlur={() => {
                  if (typeof window.gtag !== 'undefined') {
                    window.gtag('event', 'entered_dog_name', {
                      page: 'sign_up_dog_details_new',
                      dog_name: dogName
                    })
                    //TODO:DS Duplication to test category/label (remove above if working)
                    window.gtag('event', 'entered_dog_name', {
                      page: 'sign_up_dog_details_new',
                      dog_name: dogName,
                      event_category: 'entered_dog_name',
                      event_label: 'entered_dog_name'
                    })
                  }
                }}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    formikProps.setFieldValue([`name${index}`], dogName)
                    setDogName(dogName)
                    handleNext()
                    setFormErrors({})
                  }
                }}
              />
            </label>
            {formikProps.values[`name${index}`] || formErrors[`name${index}`] ? (
              <div className="text-center text-sm text-red-500 mt-2">{formErrors[`name${index}`]}</div>
            ) : null}
            {showInfoBox !== 'Name' && <>{renderInfoSection('Name')}</>}
          </div>
        </div>
      )}
      {/* Gender section */}
      {pageShown === 'gender' && (
        <div id="gender" className="question-show h-[72vh] flex items-center justify-center bg-white">
          <div
            className={`fixed justify-start md:relative h-[72vh] lg:pt-20 flex w-full flex-col items-center my-auto md:justify-center ${
              dogNameCount > 1 ? 'top-[16rem] md:-top-4' : 'top-[15rem] md:top-0'
            }`}
          >
            <span className="sans font-medium mb-4 text-center text-2xl md:text-3xl">
              Is {dogName ? dogName : 'your dog'} a boy or girl?
            </span>
            <div className="mx-auto flex w-full flex-row items-center justify-center gap-1">
              <label
                tabIndex={0}
                onClick={(e: any) => {
                  formikProps.setTouched({ [`gender${index}`]: true })
                  setFormErrors({})
                  if (typeof window.gtag !== 'undefined') {
                    window.gtag('event', 'selected_gender', {
                      page: 'sign_up_dog_details_new',
                      gender: e.target.value
                    })
                  }
                  formikProps.setFieldValue([`gender${index}`], 'MALE')
                  handleNext()
                }}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    formikProps.setTouched({ [`gender${index}`]: true })
                    formikProps.setFieldValue([`gender${index}`], 'MALE')
                    setFormErrors({})
                  }
                }}
                className={`${
                  formikProps.values[`gender${index}`] === 'MALE'
                    ? 'btn-primary-active-without-shadow !py-4'
                    : 'btn-primary !py-3 hover:!rounded-r-none'
                } btn-toggle-left-alternate w-1/3 lg:w-1/6 justify-center `}
              >
                <Field
                  data-testid="genderMale"
                  id="genderMale"
                  type="radio"
                  name={`gender${index}`}
                  value="MALE"
                  className="hidden"
                />
                Boy
              </label>
              <label
                tabIndex={0}
                onClick={(e: any) => {
                  formikProps.setTouched({ [`gender${index}`]: true })
                  setFormErrors({})
                  if (typeof window.gtag !== 'undefined') {
                    window.gtag('event', 'selected_gender', {
                      page: 'sign_up_dog_details_new',
                      gender: e.target.value
                    })
                  }
                  formikProps.setFieldValue([`gender${index}`], 'FEMALE')
                  handleNext()
                }}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    formikProps.setTouched({ [`gender${index}`]: true })
                    formikProps.setFieldValue([`gender${index}`], 'FEMALE')
                    setFormErrors({})
                  }
                }}
                className={`${
                  formikProps.values[`gender${index}`] === 'FEMALE'
                    ? 'btn-primary-active-without-shadow !py-4'
                    : 'btn-primary !py-3 hover:!rounded-l-none'
                } btn-toggle-right-alternate w-1/3 lg:w-1/6 justify-center`}
              >
                <Field
                  data-testid="genderFemale"
                  id="genderFemale"
                  type="radio"
                  name={`gender${index}`}
                  value="FEMALE"
                  className="hidden"
                />
                Girl
              </label>
            </div>
            {formikProps.values[`gender${index}`] || formErrors[`gender${index}`] ? (
              <div className="text-center text-sm text-red-500 mt-2">{formErrors[`gender${index}`]}</div>
            ) : null}
            {showInfoBox !== 'Gender' && <>{renderInfoSection('Gender')}</>}
          </div>
        </div>
      )}
      {/* Breed section */}
      {pageShown === 'breed' && (
        <div id="breed" className="question-show h-[72vh] flex items-center justify-center bg-white">
          <div
            className={`fixed justify-start md:relative ${
              isMobile ? '!top-40 h-[30rem] overscroll-auto relative' : 'fixed h-[72vh]'
            }  lg:pt-20 flex w-full flex-col items-center my-auto md:justify-center ${
              dogNameCount > 1 ? 'top-[16rem] md:-top-4' : 'top-[15rem] md:top-0'
            }`}
          >
            <span data-testid="whatBreed" className="sans font-medium mb-4 text-center text-2xl md:text-3xl">
              What breed is {dogName ? dogName : 'your dog'}?
            </span>
            <div className="mx-auto flex w-full flex-row items-center justify-center gap-1">
              <Select
                inputId="breedTestId"
                className="block w-11/12 self-center rounded-full border-2 border-secondary bg-white py-3 px-4 text-center focus:border-highlight focus:ring-highlight lg:w-1/2 2xl:w-1/3 mx-auto z-20"
                theme={theme => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: '#F5F1ED',
                    primary: '#6BA2B3',
                    primary50: '#6BA2B3'
                  }
                })}
                styles={{
                  control: (base, _state) => ({
                    ...base,
                    zIndex: 9999,
                    border: 'none',
                    boxShadow: 'none !important',
                    '*': {
                      boxShadow: 'none !important'
                    },
                    '&:hover': {
                      border: 'none !important'
                    }
                  }),
                  valueContainer: (provided, _state) => ({
                    ...provided,
                    padding: '0',
                    borderRadius: '30px'
                  }),
                  menu: provided => ({
                    ...provided,
                    borderRadius: '10px',
                    margin: '0 auto',
                    width: '92%',
                    justifyContent: 'center'
                  }),
                  dropdownIndicator: base => ({
                    ...base,
                    color: '#006685'
                  })
                }}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator
                }}
                placeholder={'Click to select or start typing'}
                // isMulti
                value={getBreed()}
                onChange={(e: any) => {
                  formikProps.setTouched({ [`breed${index}`]: true })
                  formikProps.setFieldValue([`breed${index}`], [e.value])
                  formikProps.setFieldValue([`unknownBreed${index}`], false)
                  if (typeof window.gtag !== 'undefined') {
                    window.gtag('event', 'selected_breed', {
                      page: 'sign_up_dog_details_new',
                      breed: e.value
                    })
                  }
                  listBreeds()
                }}
                onInputChange={(e: any) => {
                  listBreeds(e)
                }}
                options={sortedBreeds}
                // isOptionDisabled={() => formikProps?.values?.[`breed${index}`]?.length >= 1}
                isClearable={false}
                onBlur={() => {
                  formikProps.setTouched({ [`breed${index}`]: true })
                  formikProps.setFieldValue([`unknownBreed${index}`], false)
                  setFormErrors({})
                  listBreeds()
                }}
                required
              />
            </div>
            <div className="flex flex-row mt-3">
              <label className="flex flex-row items-center">
                {formikProps.values[`unknownBreed${index}`] === true && (
                  <Field
                    data-testid="breedUnknown"
                    id="breedUnknown"
                    type="checkbox"
                    name="unknownBreed"
                    checked={formikProps.values[`unknownBreed${index}`]}
                    className="text-fourth-checkbox h-4 w-4 rounded-full ml-1"
                    onClick={(e: any) => {
                      formikProps.setFieldValue([`breed${index}`], [])
                      formikProps.setTouched({ [`breed${index}`]: true })
                      formikProps.setFieldValue([`unknownBreed${index}`], true)
                      setFormErrors({})
                      if (typeof window.gtag !== 'undefined') {
                        window.gtag('event', 'selected_breed_unknown', {
                          page: 'sign_up_dog_details_new',
                          breed: e.target.value
                        })
                      }
                      handleNext()
                    }}
                  />
                )}
                <span
                  data-testid="dontKnowBreed"
                  className="flex-wrap pl-2 text-sm font sans font-medium hover:underline"
                  onClick={(e: any) => {
                    formikProps.setFieldValue([`breed${index}`], [])
                    formikProps.setTouched({ [`breed${index}`]: true })
                    formikProps.setFieldValue([`unknownBreed${index}`], true)
                    setFormErrors({})
                    if (typeof window.gtag !== 'undefined') {
                      window.gtag('event', 'selected_breed_unknown', {
                        page: 'sign_up_dog_details_new',
                        breed: e.target.value
                      })
                    }
                    handleNext()
                  }}
                >
                  I don't know {dogName ? dogName + "'s" : "my dog's"} breed
                </span>
              </label>
            </div>
            {/* {formikProps.values[`breed${index}`] || formErrors[`breed${index}`] ? (
              <div className="text-center text-sm text-red-500 mt-2">{formErrors[`breed${index}`]}</div>
            ) : null} */}
            {showInfoBox !== 'Breed' && <>{renderInfoSection('Breed')}</>}
          </div>
        </div>
      )}
      {/* Neutered section */}
      {pageShown === 'neutered' && (
        <div id="neutered" className="question-show h-[72vh] flex items-center justify-center bg-white">
          <div
            className={`fixed justify-start md:relative h-[72vh] lg:pt-20 flex w-full flex-col items-center my-auto md:justify-center ${
              dogNameCount > 1 ? 'top-[16rem] md:-top-4' : 'top-[15rem] md:top-0'
            }`}
          >
            <span className="sans font-medium mb-4 text-center text-2xl md:text-3xl">
              Is {dogName ? dogName : 'your dog'}{' '}
              {formikProps.values[`gender${index}`] === 'FEMALE'
                ? 'spayed'
                : formikProps.values[`gender${index}`] === 'MALE'
                  ? 'neutered'
                  : 'spayed/neutered'}
              ?
            </span>
            <div className="mx-auto flex w-full flex-row items-center justify-center gap-1">
              <label
                tabIndex={0}
                onClick={(e: any) => {
                  formikProps.setTouched({ [`neutered${index}`]: true })
                  setFormErrors({})
                  if (typeof window.gtag !== 'undefined') {
                    window.gtag('event', 'selected_neutered', {
                      page: 'sign_up_dog_details_new',
                      neutered: e.target.value
                    })
                  }
                  formikProps.setFieldValue([`neutered${index}`], 'YES')
                  handleNext()
                }}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    formikProps.setTouched({ [`neutered${index}`]: true })
                    formikProps.setFieldValue([`neutered${index}`], 'YES')
                    setFormErrors({})
                  }
                }}
                className={`${
                  formikProps.values[`neutered${index}`] === 'YES'
                    ? 'btn-primary-active-without-shadow !py-4'
                    : 'btn-primary !py-3 hover:!rounded-r-none'
                } btn-toggle-left-alternate inline-flex w-1/3 cursor-pointer flex-col flex-wrap self-center lg:w-1/6`}
              >
                <Field
                  data-testid="neuteredYes"
                  id="neuteredYes"
                  type="radio"
                  name={`neutered${index}`}
                  value="YES"
                  className="hidden"
                />
                Yes
              </label>
              <label
                tabIndex={0}
                onClick={(e: any) => {
                  formikProps.setTouched({ [`neutered${index}`]: true })
                  setFormErrors({})
                  if (typeof window.gtag !== 'undefined') {
                    window.gtag('event', 'selected_neutered', {
                      page: 'sign_up_dog_details_new',
                      neutered: e.target.value
                    })
                  }
                  formikProps.setFieldValue([`neutered${index}`], 'NO')
                  handleNext()
                }}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    formikProps.setTouched({ [`neutered${index}`]: true })
                    formikProps.setFieldValue([`neutered${index}`], 'NO')
                    setFormErrors({})
                  }
                }}
                className={`${
                  formikProps.values[`neutered${index}`] === 'NO'
                    ? 'btn-primary-active-without-shadow !py-4'
                    : 'btn-primary !py-3 hover:!rounded-l-none'
                } btn-toggle-right-alternate inline-flex w-1/3 cursor-pointer flex-col flex-wrap self-center lg:w-1/6`}
              >
                <Field
                  data-testid="neuteredNo"
                  id="neuteredNo"
                  type="radio"
                  name={`neutered${index}`}
                  value="NO"
                  className="hidden"
                />
                No
              </label>
            </div>
            {formikProps.values[`neutered${index}`] || formErrors[`neutered${index}`] ? (
              <div className="text-center text-sm text-red-500 mt-2">{formErrors[`neutered${index}`]}</div>
            ) : null}
            {showInfoBox !== 'Neutered' && <>{renderInfoSection('Neutered')}</>}
          </div>
        </div>
      )}
      {/* Date of birth section */}
      {pageShown === 'dobYear' && (
        <div id="dobYear" className="question-show h-[72vh] flex items-center justify-center bg-white">
          <div
            className={`justify-start md:relative ${
              mobileScroll ? '!top-40 h-[20rem] overscroll-auto relative' : 'fixed h-[72vh]'
            } lg:pt-20 flex w-full flex-col items-center my-auto md:justify-center ${
              dogNameCount > 1 ? 'top-[16rem] md:top-4' : 'top-[15rem] md:top-0'
            }`}
          >
            <span className="sans font-medium mb-4 text-center text-2xl md:text-3xl">
              When was {dogName ? dogName : 'your dog'} born?
            </span>
            <p className="pb-4 text-center">Not sure? An estimate is fine.</p>
            <div className="flex flex-col md:flex-row w-full lg:w-1/3">
              <label className="mb-6 flex flex-col items-center text-sm font-normal text-base w-full">
                <Field
                  data-testid="dobYear"
                  name={`dobYear${index}`}
                  component="select"
                  label="date"
                  id="dobYear"
                  className="block w-2/3 lg:w-full self-center rounded-full border-2 border-secondary bg-white py-3 px-4 lg:px-0 lg:mr-2 lg:self-end text-center focus:border-highlight focus:ring-highlight text-base"
                  onChange={(e: any) => {
                    formikProps.setFieldValue([`dobYear${index}`], e.target.value)
                    formikProps.setTouched({ [`dobYear${index}`]: true })
                    setFormErrors({})
                    if (parseInt(e.target.value) >= todayMinus1Year.year()) {
                      formikProps.setFieldValue([`exercise${index}`], 'GROWINGPUPPY')
                    } else {
                      formikProps.setFieldValue([`exercise${index}`], '')
                    }

                    if (e.target.value == '') {
                      formikProps.setFieldValue([`dobMonth${index}`], '')
                    }

                    if (typeof window.gtag !== 'undefined') {
                      window.gtag('event', 'selected_year', {
                        page: 'sign_up_dog_details_new',
                        dob_year: e.target.value
                      })
                    }
                  }}
                >
                  <option value="">Year</option>
                  {years?.map((year: any) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </Field>
              </label>

              <label className="flex flex-col items-center text-sm font-normal text-base w-full">
                <Field
                  data-testid="dobMonth"
                  name={`dobMonth${index}`}
                  component="select"
                  label="date"
                  id="dobMonth"
                  className="block w-2/3 lg:w-full self-center rounded-full border-2 border-secondary bg-white py-3 px-4 lg:px-0 lg:ml-2 lg:self-start text-center focus:border-highlight focus:ring-highlight text-base"
                  onChange={(e: any) => {
                    formikProps.setFieldValue([`dobMonth${index}`], e.target.value)
                    formikProps.setTouched({ [`dobMonth${index}`]: true })
                    setFormErrors({})
                    // When month is entered, check if the year and month is less then 1 year from today
                    if (
                      todayMinus1Year.diff(
                        dayjs(`${formikProps.values[`dobYear${index}`]}-${e.target.value}-01`),
                        'year',
                        true
                      ) <= 0
                    ) {
                      formikProps.setFieldValue([`exercise${index}`], 'GROWINGPUPPY')
                    } else {
                      formikProps.setFieldValue([`exercise${index}`], '')
                    }

                    if (typeof window.gtag !== 'undefined') {
                      window.gtag('event', 'selected_month', {
                        page: 'sign_up_dog_details_new',
                        dob_month: e.target.value
                      })
                    }
                  }}
                >
                  <option value="">Month</option>
                  {months?.map((month: any, ind: number) => (
                    <option
                      value={month.value}
                      key={ind}
                      disabled={
                        formikProps.values[`dobYear${index}`] === currentDate[2] &&
                        parseInt(month.value) > parseInt(currentDate[1])
                          ? true
                          : false
                      }
                    >
                      {month.month}
                    </option>
                  ))}
                </Field>
                {formikProps.values[`dobYear${index}`] >= todayMinus1Year.year() && (
                  <span className="block text-xs font-medium text-ddGrey pt-1 lg:ml-4">{'Required'}</span>
                )}
                {formikProps.values[`dobYear${index}`] < todayMinus1Year.year() && (
                  <span className="block text-xs font-medium text-ddGrey pt-1 lg:ml-4">{'Optional'}</span>
                )}
              </label>
            </div>
            {formErrors[`dobYear${index}`] ? (
              <div className="text-center text-sm text-red-500 mt-2">{formErrors[`dobYear${index}`]}</div>
            ) : null}
            {formErrors[`dobMonth${index}`] ? (
              <div className="text-center text-sm text-red-500 mt-2">{formErrors[`dobMonth${index}`]}</div>
            ) : null}
            {showInfoBox !== 'DOB' && <>{renderInfoSection('DOB')}</>}
          </div>
        </div>
      )}
      {/* Weight section */}
      {pageShown === 'weight' && (
        <div id="weight" className={`question-show flex h-[72vh] items-center justify-center bg-white`}>
          <div
            className={`justify-start md:relative ${
              mobileScroll ? '!top-36 h-64 overscroll-auto relative' : 'fixed h-[72vh]'
            } lg:pt-20 flex w-full flex-col items-center my-auto md:justify-center ${
              dogNameCount > 1 ? 'top-[16rem] md:-top-4' : 'top-[15rem] md:top-0'
            }`}
          >
            <span className="sans font-medium mb-4 text-center text-2xl md:text-3xl">
              How much does {dogName ? dogName : 'your dog'} weigh?
            </span>
            <p className="pb-4 text-center">Not sure? An estimate is fine.</p>
            <label className="mb-0 ml-0 flex flex-col items-center w-full">
              <div className="flex flex-row items-center w-2/3">
                <Field
                  data-testid="weight"
                  name={`weight${index}`}
                  id="weight"
                  type="number"
                  pattern="\d*"
                  className="block w-full self-center rounded-full border-2 border-secondary bg-white py-3 px-4 text-center focus:border-highlight focus:ring-highlight lg:w-4/6 mx-auto"
                  placeholder="Your dog's weight"
                  onChange={(e: any) => {
                    formikProps.setTouched({ [`weight${index}`]: true })
                    if (parseInt(e.target.value) > 0) {
                      if (parseInt(e.target.value) >= 75) {
                        formikProps.setFieldValue([`weight${index}`], parseInt(e.target.value))
                        setFormErrors({ [`${pageShown}${index}`]: 'Weight must be below 75kg' })
                      } else {
                        setFormErrors({})
                        formikProps.setFieldValue([`weight${index}`], parseInt(e.target.value))
                      }
                    } else {
                      formikProps.setFieldValue([`weight${index}`], '')
                    }
                  }}
                  onBlur={(e: any) => {
                    if (typeof window.gtag !== 'undefined') {
                      window.gtag('event', 'entered_weight', {
                        page: 'sign_up_dog_details_new',
                        weight: e.target.value
                      })
                    }
                  }}
                  onKeyDown={(e: any) => {
                    // Allow only digits, Backspace, and Arrow keys
                    if (
                      !/[0-9]/.test(e.key) &&
                      e.key !== 'Backspace' &&
                      e.key !== 'ArrowLeft' &&
                      e.key !== 'ArrowRight'
                    ) {
                      e.preventDefault()
                    }
                    if (e.key === 'Enter') {
                      if (parseInt(e.target.value) > 0) {
                        if (parseInt(e.target.value) >= 75) {
                          formikProps.setFieldValue([`weight${index}`], parseInt(e.target.value))
                          setFormErrors({ [`${pageShown}${index}`]: 'Weight must be below 75kg' })
                        } else {
                          handleNext()
                          setFormErrors({})
                          formikProps.setFieldValue([`weight${index}`], parseInt(e.target.value))
                        }
                      } else {
                        formikProps.setFieldValue([`weight${index}`], '')
                      }
                    }
                  }}
                />
                <span className="relative ml-2 text-sm font-medium text-gray-700 right-[14%] lg:right-[20%]">kg</span>
              </div>
            </label>
            {formErrors[`weight${index}`] ? (
              <div className="text-center text-sm text-red-500 mt-2">{formErrors[`weight${index}`]}</div>
            ) : null}
            {showInfoBox !== 'Weight' && <>{renderInfoSection('Weight')}</>}
          </div>
        </div>
      )}
      {/* Physique section */}
      {pageShown === 'physique' && (
        <div id="physique" className="question-show h-[72vh] flex items-center justify-center bg-white">
          <div
            className={`justify-start md:relative ${
              mobileScroll ? '!top-40 h-[22rem] overscroll-auto relative' : 'fixed h-[72vh]'
            } lg:pt-20 flex w-full flex-col items-center my-auto md:justify-center ${
              dogNameCount > 1 ? 'top-[16rem] md:top-4' : 'top-[15rem] md:top-0'
            }`}
          >
            <span className="sans font-medium mb-4 text-center text-2xl md:text-3xl">
              What is {dogName ? dogName + "'s" : "your dog's"} physique?
            </span>
            <p className="pb-4 text-center">You can easily update this if your dog changes shape.</p>
            <div className="mx-auto flex w-full flex-row items-center justify-center gap-x-[2px]">
              <label
                tabIndex={0}
                onClick={(e: any) => {
                  setFormErrors({})
                  if (typeof window.gtag !== 'undefined') {
                    window.gtag('event', 'selected_physique', {
                      page: 'sign_up_dog_details_new',
                      physique: e.target.value
                    })
                  }
                  formikProps.setFieldValue([`physique${index}`], 'SLIM')
                  handleNext()
                }}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    formikProps.setFieldValue([`physique${index}`], 'SLIM')
                    setFormErrors({})
                  }
                }}
                className={`${
                  formikProps.values[`physique${index}`] === 'SLIM'
                    ? 'btn-primary-active-without-shadow-or-rounding'
                    : 'inline-flex cursor-pointer border-2 border-secondary bg-white py-2 px-4 text-center lg:hover:border-ddBlue lg:hover:bg-ddBlue lg:hover:text-black lg:focus:text-black lg:focus:outline-none disabled:opacity-40'
                } dog-image-wrapper inline-flex w-[30%] cursor-pointer flex-col flex-wrap items-center self-center rounded-l-3xl md:w-1/6 lg:w-[11%]`}
              >
                <StaticImage
                  objectFit={'contain'}
                  className="dog-physique block h-20 w-full"
                  src="../../../../images/dog-slim-2.svg"
                  alt="Slim Sized Dog"
                />
                <Field
                  data-testid="physique1"
                  id="physique1"
                  type="radio"
                  name={`physique${index}`}
                  value="SLIM"
                  className="hidden"
                />
                <span>Slim</span>
              </label>
              <label
                tabIndex={0}
                onClick={(e: any) => {
                  setFormErrors({})
                  if (typeof window.gtag !== 'undefined') {
                    window.gtag('event', 'selected_physique', {
                      page: 'sign_up_dog_details_new',
                      physique: e.target.value
                    })
                  }
                  formikProps.setFieldValue([`physique${index}`], 'PERFECT')
                  handleNext()
                }}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    formikProps.setFieldValue([`physique${index}`], 'PERFECT')
                    setFormErrors({})
                  }
                }}
                className={`${
                  formikProps.values[`physique${index}`] === 'PERFECT'
                    ? 'btn-primary-active-without-shadow-or-rounding'
                    : 'inline-flex cursor-pointer border-2 border-secondary bg-white py-2 px-4 text-center lg:hover:border-ddBlue lg:hover:bg-ddBlue lg:hover:text-black lg:focus:text-black lg:focus:outline-none disabled:opacity-40'
                } dog-image-wrapper inline-flex w-[30%] cursor-pointer flex-col flex-wrap items-center self-center md:w-1/6 lg:w-[11%] rounded-none`}
              >
                <StaticImage
                  objectFit={'contain'}
                  className="dog-physique block h-20 w-full"
                  src="../../../../images/dog-perfect-2.svg"
                  alt="Perfect Sized Dog"
                />
                <Field
                  data-testid="physique2"
                  id="physique2"
                  type="radio"
                  name={`physique${index}`}
                  value="PERFECT"
                  className="hidden"
                />
                <span>Perfect</span>
              </label>
              <label
                tabIndex={0}
                onClick={(e: any) => {
                  setFormErrors({})
                  if (typeof window.gtag !== 'undefined') {
                    window.gtag('event', 'selected_physique', {
                      page: 'sign_up_dog_details_new',
                      physique: e.target.value
                    })
                  }
                  formikProps.setFieldValue([`physique${index}`], 'OVERWEIGHT')
                  handleNext()
                }}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    formikProps.setFieldValue([`physique${index}`], 'OVERWEIGHT')
                    setFormErrors({})
                  }
                }}
                className={`${
                  formikProps.values[`physique${index}`] === 'OVERWEIGHT'
                    ? 'btn-primary-active-without-shadow-or-rounding'
                    : 'inline-flex cursor-pointer border-2 border-secondary bg-white py-2 px-4 text-center lg:hover:border-ddBlue lg:hover:bg-ddBlue lg:hover:text-black lg:focus:text-black lg:focus:outline-none disabled:opacity-40'
                } dog-image-wrapper inline-flex w-[30%] cursor-pointer flex-col flex-wrap items-center self-center rounded-r-3xl md:w-1/6 lg:w-[11%]`}
              >
                <StaticImage
                  objectFit={'contain'}
                  className="dog-physique block h-20 w-full"
                  src="../../../../images/dog-chunky-2.svg"
                  alt="Chunky Sized Dog"
                />
                <Field id="physique3" type="radio" name={`physique${index}`} value="OVERWEIGHT" className="hidden" />
                <span>Chunky</span>
              </label>
            </div>
            {formikProps.values[`physique${index}`] || formErrors[`physique${index}`] ? (
              <div className="text-center text-sm text-red-500 mt-2">{formErrors[`physique${index}`]}</div>
            ) : null}
            {showInfoBox !== 'Physique' && <>{renderInfoSection('Physique')}</>}
          </div>
        </div>
      )}
      {/* Exercise section */}
      {formikProps.values[`exercise${index}`] !== 'GROWINGPUPPY' && pageShown === 'exercise' && (
        <div id="exercise" className="question-show h-[72vh] flex items-center justify-center bg-white">
          <div
            className={`justify-start md:relative ${
              mobileScroll ? '!top-40 h-[20rem] overscroll-auto relative' : 'fixed h-[72vh]'
            } lg:pt-20 flex w-full flex-col items-center my-auto md:justify-center ${
              dogNameCount > 1 ? 'top-[16rem] md:-top-4' : 'top-[15rem] md:top-0'
            }`}
          >
            <span className="sans font-medium mb-4 text-center text-2xl md:text-3xl">
              How much exercise does {dogName ? dogName : 'your dog'} get per day?
            </span>
            <p className="pb-4 text-center">Including walks and any zoomies.</p>
            <div className="mx-auto flex w-full flex-row items-center justify-center gap-x-[2px]">
              <label
                tabIndex={0}
                onClick={(e: any) => {
                  setFormErrors({})
                  if (typeof window.gtag !== 'undefined') {
                    window.gtag('event', 'selected_exercise', {
                      page: 'sign_up_dog_details_new',
                      exercise: e.target.value
                    })
                  }
                  formikProps.setFieldValue([`exercise${index}`], 'LOW')
                  handleNext()
                }}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    formikProps.setFieldValue([`exercise${index}`], 'LOW')
                    setFormErrors({})
                  }
                }}
                className={`${
                  formikProps.values[`exercise${index}`] === 'LOW'
                    ? 'btn-primary-active-without-shadow !py-4'
                    : 'btn-primary !py-3 lg:!hover:border-secondary lg:!hover:bg-white hover:!rounded-r-none'
                } btn-toggle-left-alternate inline-flex w-[30%] cursor-pointer flex-col flex-wrap justify-center self-auto md:w-1/6 lg:w-[11%]`}
              >
                <Field data-testid="LOW" type="radio" name={`exercise${index}`} value="LOW" className="hidden" />
                {'0 - 1 Hour'}
              </label>
              <label
                tabIndex={0}
                onClick={(e: any) => {
                  setFormErrors({})
                  if (typeof window.gtag !== 'undefined') {
                    window.gtag('event', 'selected_exercise', {
                      page: 'sign_up_dog_details_new',
                      exercise: e.target.value
                    })
                  }
                  formikProps.setFieldValue([`exercise${index}`], 'MEDIUM')
                  handleNext()
                }}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    formikProps.setFieldValue([`exercise${index}`], 'MEDIUM')
                    setFormErrors({})
                  }
                }}
                className={`${
                  formikProps.values[`exercise${index}`] === 'MEDIUM'
                    ? 'btn-primary-active-without-shadow !py-4 !border-none'
                    : 'border-2 !py-3 border-secondary bg-white text-center lg:hover:border-ddBlue lg:hover:bg-ddBlue lg:hover:text-black lg:focus:text-black lg:focus:outline-none disabled:opacity-40'
                } btn-toggle-center-full inline-flex w-[30%] cursor-pointer flex-col flex-wrap justify-center self-auto md:w-1/6 lg:w-[11%]`}
              >
                <Field data-testid="medium" type="radio" name={`exercise${index}`} value="MEDIUM" className="hidden" />
                {'1 - 2 Hours'}
              </label>
              <label
                tabIndex={0}
                onClick={(e: any) => {
                  setFormErrors({})
                  if (typeof window.gtag !== 'undefined') {
                    window.gtag('event', 'selected_exercise', {
                      page: 'sign_up_dog_details_new',
                      exercise: e.target.value
                    })
                  }
                  formikProps.setFieldValue([`exercise${index}`], 'HIGH')
                  handleNext()
                }}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    formikProps.setFieldValue([`exercise${index}`], 'HIGH')
                    setFormErrors({})
                  }
                }}
                className={`${
                  formikProps.values[`exercise${index}`] === 'HIGH'
                    ? 'btn-primary-active-without-shadow !py-4'
                    : 'btn-primary !py-3 lg:!hover:border-secondary lg:!hover:bg-white hover:!rounded-l-none'
                } btn-toggle-right-alternate inline-flex w-[30%] cursor-pointer flex-col flex-wrap justify-center self-auto md:w-1/6 lg:w-[11%]`}
              >
                <Field type="radio" name={`exercise${index}`} value="HIGH" className="hidden" />
                {'2+ Hours'}
              </label>
            </div>
            {formikProps.values[`exercise${index}`] || formErrors[`exercise${index}`] ? (
              <div className="text-center text-sm text-red-500 mt-2">{formErrors[`exercise${index}`]}</div>
            ) : null}
            {showInfoBox !== 'Exercise' && <>{renderInfoSection('Exercise')}</>}
          </div>
        </div>
      )}
      {/* Allergies section */}
      {pageShown === 'medicalConditionOrAllergies' && (
        <div
          id="medicalConditionOrAllergies"
          className="question-show h-[72vh] flex items-center justify-center bg-white"
        >
          <div
            className={`justify-start md:relative ${
              mobileScroll ? '!top-40 h-[16rem] overscroll-auto relative' : 'fixed h-[72vh]'
            } lg:pt-20 flex w-full flex-col items-center my-auto md:justify-center ${
              dogNameCount > 1 ? 'top-[16rem] md:-top-4' : 'top-[15rem] md:top-0'
            }`}
          >
            <span className="sans font-medium mb-4 text-center text-2xl md:text-3xl">
              Does {dogName ? dogName : 'your dog'} have any medical conditions or allergies?
            </span>
            <div className="mx-auto flex w-full flex-row items-center justify-center gap-1">
              <label
                tabIndex={0}
                onClick={(e: any) => {
                  setFormErrors({})
                  if (typeof window.gtag !== 'undefined') {
                    window.gtag('event', 'selected_medical_conditions_or_allergies', {
                      page: 'sign_up_dog_details_new',
                      medical_conditions_or_allergies: e.target.value
                    })
                  }
                  formikProps.setFieldValue([`medicalConditionOrAllergies${index}`], 'YES')
                  handleNext()
                }}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    formikProps.setFieldValue([`medicalConditionOrAllergies${index}`], 'YES')
                    setFormErrors({})
                  }
                }}
                className={`${
                  formikProps.values[`medicalConditionOrAllergies${index}`] === 'YES'
                    ? 'btn-primary-active-without-shadow py-4'
                    : 'btn-primary !py-3 hover:!rounded-r-none'
                } btn-toggle-left-alternate inline-flex w-1/3 cursor-pointer flex-col flex-wrap self-center lg:w-1/6`}
              >
                <Field type="radio" name={`medicalConditionOrAllergies${index}`} value="YES" className="hidden" />
                {'Yes'}
              </label>
              <label
                tabIndex={0}
                onClick={(e: any) => {
                  setFormErrors({})
                  if (typeof window.gtag !== 'undefined') {
                    window.gtag('event', 'selected_medical_conditions_or_allergies', {
                      page: 'sign_up_dog_details_new',
                      medical_conditions_or_allergies: e.target.value
                    })
                  }
                  formikProps.setFieldValue([`medicalConditionOrAllergies${index}`], 'NO')
                  handleNext()
                }}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    formikProps.setFieldValue([`medicalConditionOrAllergies${index}`], 'NO')
                    setFormErrors({})
                  }
                }}
                className={`${
                  formikProps.values[`medicalConditionOrAllergies${index}`] === 'NO'
                    ? 'btn-primary-active-without-shadow !py-4'
                    : 'btn-primary !py-3 hover:!rounded-l-none'
                } btn-toggle-right-alternate inline-flex w-1/3 cursor-pointer flex-col flex-wrap self-center lg:w-1/6`}
              >
                <Field
                  data-testid="medicalConditionOrAllergies1"
                  type="radio"
                  name={`medicalConditionOrAllergies${index}`}
                  value="NO"
                  className="hidden"
                />
                {'No'}
              </label>
            </div>
            {formikProps.values[`medicalConditionOrAllergies${index}`] ||
            formErrors[`medicalConditionOrAllergies${index}`] ? (
              <div className="text-center text-sm text-red-500 mt-2">
                {formErrors[`medicalConditionOrAllergies${index}`]}
              </div>
            ) : null}
            {showInfoBox !== 'MedicalConditionOrAllergies' && <>{renderInfoSection('MedicalConditionOrAllergies')}</>}
          </div>
        </div>
      )}
      {/* Fussy dog section */}
      {pageShown === 'fussy' && (
        <div id="fussy" className="question-show h-[72vh] flex items-center justify-center bg-white">
          <div
            className={`justify-start md:relative ${
              isMobile ? '!top-40 h-[30rem] overscroll-auto relative' : 'fixed h-[72vh]'
            } lg:pt-20 flex w-full flex-col items-center my-auto md:justify-center md:top-0 ${
              dogNameCount > 1 ? 'top-[16rem]' : 'top-[14rem]'
            }`}
          >
            <span className="sans font-medium mb-4 text-center text-2xl md:text-3xl">
              Is {dogName ? dogName : 'your dog'} fussy at the moment?
            </span>
            <div className="mx-auto flex w-full flex-col lg:flex-row items-center justify-center gap-y-1 lg:gap-x-[2px]">
              <label
                tabIndex={0}
                onClick={(e: any) => {
                  setFormErrors({})
                  if (typeof window.gtag !== 'undefined') {
                    window.gtag('event', 'selected_fussy', {
                      page: 'sign_up_dog_details_new',
                      fussy: e.target.value
                    })
                  }
                  formikProps.setFieldValue([`fussy${index}`], 'LOW')
                  handleNext()
                }}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    formikProps.setFieldValue([`fussy${index}`], 'LOW')
                    setFormErrors({})
                  }
                }}
                className={`${
                  formikProps.values[`fussy${index}`] === 'LOW'
                    ? 'btn-primary-active-without-shadow-or-rounding w-[82%] md:w-[52%] h-[5.5rem]'
                    : 'inline-flex cursor-pointer border-2 border-secondary bg-white py-2 px-4 text-center lg:hover:border-ddBlue lg:hover:bg-ddBlue lg:hover:text-black lg:focus:text-black lg:focus:outline-none disabled:opacity-40'
                } dog-image-wrapper inline-flex w-4/5 cursor-pointer flex-col flex-wrap items-center self-center rounded-t-3xl lg:rounded-r-none lg:rounded-l-3xl md:w-1/2 lg:w-[22%] min-h-[5rem] justify-center`}
              >
                <Field data-testid="LOW" type="radio" name={`fussy${index}`} value="LOW" className="hidden" />
                <div className="flex flex-col">
                  <p className="font-bold">Licks the bowl clean</p>
                  <p className="text-sm">We don't have any problems at meal times</p>
                </div>
              </label>
              <label
                tabIndex={0}
                onClick={(e: any) => {
                  setFormErrors({})
                  if (typeof window.gtag !== 'undefined') {
                    window.gtag('event', 'selected_fussy', {
                      page: 'sign_up_dog_details_new',
                      fussy: e.target.value
                    })
                  }
                  formikProps.setFieldValue([`fussy${index}`], 'MEDIUM')
                  handleNext()
                }}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    formikProps.setFieldValue([`fussy${index}`], 'MEDIUM')
                    setFormErrors({})
                  }
                }}
                className={`${
                  formikProps.values[`fussy${index}`] === 'MEDIUM'
                    ? 'btn-primary-active-without-shadow-or-rounding w-[82%] md:w-[52%] h-[5.5rem]'
                    : 'inline-flex cursor-pointer border-2 border-secondary bg-white py-2 px-4 text-center lg:hover:border-ddBlue lg:hover:bg-ddBlue lg:hover:text-black lg:focus:text-black lg:focus:outline-none disabled:opacity-40'
                } dog-image-wrapper inline-flex w-4/5 cursor-pointer flex-col flex-wrap items-center self-center md:w-1/2 lg:w-[22%] rounded-none lg:rounded-none min-h-[5rem] justify-center`}
              >
                <Field data-testid="medium" type="radio" name={`fussy${index}`} value="MEDIUM" className="hidden" />
                <div className="flex flex-col">
                  <p className="font-semibold">A picky eater</p>
                  <p className="text-sm">Can be choosy about new tastes and textures</p>
                </div>
              </label>
              <label
                tabIndex={0}
                onClick={(e: any) => {
                  setFormErrors({})
                  if (typeof window.gtag !== 'undefined') {
                    window.gtag('event', 'selected_fussy', {
                      page: 'sign_up_dog_details_new',
                      fussy: e.target.value
                    })
                  }
                  formikProps.setFieldValue([`fussy${index}`], 'HIGH')
                  handleNext()
                }}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    formikProps.setFieldValue([`fussy${index}`], 'HIGH')
                    setFormErrors({})
                  }
                }}
                className={`${
                  formikProps.values[`fussy${index}`] === 'HIGH'
                    ? 'btn-primary-active-without-shadow-or-rounding w-[82%] md:w-[52%] h-[5.5rem]'
                    : 'inline-flex cursor-pointer border-2 border-secondary bg-white py-2 px-4 text-center lg:hover:border-ddBlue lg:hover:bg-ddBlue lg:hover:text-black lg:focus:text-black lg:focus:outline-none disabled:opacity-40'
                } dog-image-wrapper inline-flex w-4/5 cursor-pointer flex-col flex-wrap items-center self-center rounded-b-3xl lg:rounded-l-none lg:rounded-r-3xl md:w-1/2 lg:w-[22%] min-h-[5rem] justify-center`}
              >
                <Field type="radio" name={`fussy${index}`} value="HIGH" className="hidden" />
                <div className="flex flex-col">
                  <p className="font-semibold">A fussy pup</p>
                  <p className="text-sm">Meal times can be a little stressful</p>
                </div>
              </label>
            </div>
            {formikProps.values[`fussy${index}`] || formErrors[`fussy${index}`] ? (
              <div className="text-center text-sm text-red-500 mt-2">{formErrors[`fussy${index}`]}</div>
            ) : null}
          </div>
        </div>
      )}
      {/* Next section */}
      {pageShown === 'next' && (
        <div id="next" className="question-show h-[72vh] flex items-center justify-center bg-white">
          <div
            className={`justify-start md:relative ${
              mobileScroll ? '!top-40 h-[18rem] overscroll-auto relative' : 'fixed h-[72vh]'
            } lg:pt-20 flex w-full flex-col items-center my-auto md:justify-center ${
              dogNameCount > 1 ? 'top-[16rem] md:top-20' : 'top-[15rem] md:top-0'
            }`}
          >
            <div
              className={`relative mb-8 flex w-4/5 flex-col items-center justify-center rounded-3xl bg-background lg:w-3/5 ${
                dogNameCount === maxItems ? 'pb-4' : ''
              }`}
            >
              {dogNameCount === 1 && (
                <h4 className="mt-8 pb-4 text-xl md:text-3xl">
                  Ready to see {dogName ? dogName + "'s" : "your dog's"} plan?{' '}
                </h4>
              )}
              {dogNameCount >= 2 && (
                <h4 className="mx-2 md:mx-0 mt-8 lg:pb-4 text-xl md:text-3xl">Ready to see your dogs' plan? </h4>
              )}
              <div className="mt-4 flex w-full justify-center py-2">
                <button
                  onClick={(_e: any) => {
                    if (typeof window.gtag !== 'undefined') {
                      window.gtag('event', 'submit_dog_details_alternate', {
                        page: 'sign_up_dog_details_alternate'
                      })
                      //TODO:DS Duplication to test category/label (remove above if working)
                      window.gtag('event', 'submit_dog_details_alternate', {
                        page: 'sign_up_dog_details_alternate',
                        event_category: 'submit_dog_details_alternate',
                        event_label: 'submit_dog_details_alternate'
                      })
                    }
                    setSendConsoleErrorToCloudWatch(true)
                    handleSubmitForm(formikProps, 'submit', index)
                  }}
                  type="button"
                  data-testid="calculate"
                  className={`${
                    loading ? 'opacity-50' : ''
                  } calculate-plan btn-cta scale-animation w-3/4 items-center md:w-3/5`}
                  disabled={loading}
                >
                  {loading ? (
                    <div className="align-center flex justify-center">
                      <div className="border-white-900 mr-2 h-5 w-5 animate-spin rounded-full border-b-2"></div>
                      <div>Calculating</div>
                    </div>
                  ) : (
                    'Next'
                  )}
                </button>
              </div>
              {dogNameCount < 5 && (
                <div className="mb-8 flex justify-center p-2">
                  <a
                    data-testid="btnAddPlus"
                    className={`mr-2 mx-1 my-1 flex h-6 w-6 items-center bg-background !px-0 !py-0 !text-4xl text-primary`}
                    onClick={() => {
                      if (typeof window.gtag !== 'undefined') {
                        window.gtag('event', 'signup_add_dog', {
                          page: 'sign_up_dog_details_new'
                        })
                      }
                      handleSubmitForm(formikProps, 'addDog', index)
                    }}
                  >
                    <StaticImage src={'../../../../images/dog-details/dog-details-add.svg'} alt="Add" />
                  </a>

                  <button
                    className="font-sans mb-0 w-full self-center"
                    onClick={() => {
                      if (typeof window.gtag !== 'undefined') {
                        window.gtag('event', 'signup_add_dog', {
                          page: 'sign_up_dog_details_new'
                        })
                      }
                      handleSubmitForm(formikProps, 'addDog', index)
                    }}
                  >
                    Add another dog
                  </button>
                </div>
              )}
            </div>
            <div className="flex flex-row">
              <a
                data-testid="btnBack"
                className={`mr-2 mx-1 my-1 flex h-6 w-6 items-center bg-white !px-0 !py-0 !text-4xl text-primary`}
                onClick={() => {
                  handleBack()
                }}
              >
                <StaticImage className="w-8" src={'../../../../images/dog-details/dog-details-back.svg'} alt="Back" />
              </a>

              <button
                className="text-primary font-sans mb-0 w-full self-center"
                onClick={() => {
                  handleBack()
                }}
              >
                Back
              </button>
            </div>
          </div>
        </div>
      )}
      {pageShown !== 'next' && (
        <StickyFooter
          className={`z-[3] fixed bottom-0 left-0 right-0 lg:max-h-20 bg-[#F8F2EC] w-full lg:w-11/12 mx-auto lg:rounded-t-3xl`}
        >
          <div className="w-full lg:w-3/4 flex flex-row relative items-center justify-center lg:justify-end mx-auto">
            {!backButtonVisible && (
              <button
                data-testid="dogDetailsBack"
                onClick={handleBack}
                type="button"
                className="rounded-full border border-primary bg-white px-12 py-3 hover:underline focus:underline hidden lg:flex justify-center items-center mr-4"
              >
                <FontAwesomeIcon icon={faChevronLeft} className="h-4 w-4 mr-2" aria-hidden="true" />
                Back
              </button>
            )}
            <div
              className={`${
                pageShown === 'name' ? 'mx-auto justify-center' : 'flex-row'
              } w-full flex justify-center items-center`}
            >
              <a
                className={`mr-3 flex h-[3.5rem] w-[3.5rem] items-center text-primary ${pageShown === 'name' ? 'hidden' : 'lg:hidden'}`}
                onClick={handleBack}
              >
                <StaticImage src="../../../../images/back-arrow-mobile.png" alt="Back" />
              </a>
              <button
                data-testid="next"
                onClick={() => {
                  if (
                    !formikProps.values[`unknownBreed${index}`] &&
                    formikProps.values[`breed${index}`].length === 0 &&
                    pageShown === 'breed'
                  ) {
                    // Gtag for nothing selected on breed page
                    if (typeof window.gtag !== 'undefined') {
                      window.gtag('event', 'selected_breed_not_entered', {
                        page: 'sign_up_dog_details_new'
                      })
                    }
                  }
                  if (formikProps.values[`${pageShown}${index}`]) {
                    if (!formErrors[`weight${index}`]) {
                      handleNext()
                    }
                  } else {
                    const pageError = formValidationErrors.find((e: any) => e.key === pageShown)
                    setFormErrors({
                      [`${pageShown}${index}`]: pageError?.error
                    })
                  }
                }}
                type="button"
                className={`${
                  !formikProps.values[`${pageShown}${index}`] ? '!bg-[#80B2C2]' : ''
                } btn-cta scale-animation !py-3 lg:!py-4 !text-xl !font-bold !flex ${
                  pageShown === 'name' ? 'w-3/4 mx-auto' : 'w-11/12 lg:w-1/2 lg:ml-auto'
                } !justify-center !items-center my-1`}
              >
                Next
                <FontAwesomeIcon icon={faChevronRight} className="h-4 w-4 ml-2" aria-hidden="true" />
              </button>
            </div>
          </div>
        </StickyFooter>
      )}
      <RemoveDogSignupModal
        show={removeDogModalShow}
        setShow={setRemoveDogModalShow}
        dogIndex={removeDogIndex}
        dogName={formikProps.values[`name${removeDogIndex}`]}
        setRemoveDogConfirm={setRemoveDogConfirm}
      />
    </>
  )
}

export default DogAccordion
