import dayjs from 'dayjs'

export const getRemainingTimeUntilMsTimestamp = (hours: boolean, countdownTimestamp: any) => {
  // const userTimestamp = dayjs(countdownTimestamp).add(1, 'hour')
  const userTimestamp = dayjs(countdownTimestamp)
  const nowTimestamp = dayjs()
  if (hours) {
    if (userTimestamp.isBefore(nowTimestamp)) {
      return {
        seconds: null,
        minutes: null,
        hours: null
      }
    }
    return {
      seconds: getRemainingSeconds(nowTimestamp, userTimestamp),
      minutes: getRemainingMinutes(nowTimestamp, userTimestamp),
      hours: getRemainingHours(nowTimestamp, userTimestamp)
    }
  } else {
    if (userTimestamp.isBefore(nowTimestamp)) {
      return {
        seconds: null,
        minutes: null
      }
    }
    return {
      seconds: getRemainingSeconds(nowTimestamp, userTimestamp),
      minutes: getRemainingMinutes(nowTimestamp, userTimestamp)
    }
  }
}

const getRemainingSeconds = (nowTimestamp: any, userTimestamp: any) => {
  const seconds = userTimestamp.diff(nowTimestamp, 'seconds') % 60
  return padWithZeros(seconds, 2)
}

const getRemainingMinutes = (nowTimestamp: any, userTimestamp: any) => {
  const minutes = userTimestamp.diff(nowTimestamp, 'minutes') % 60
  return padWithZeros(minutes, 2)
}

const getRemainingHours = (nowTimestamp: any, userTimestamp: any) => {
  const hours = userTimestamp.diff(nowTimestamp, 'hours') % 60
  return padWithZeros(hours, 2)
}

const padWithZeros = (number: any, minLength: any) => {
  const numberString = number.toString()
  if (numberString.length >= minLength) return numberString
  return '0'.repeat(minLength - numberString.length) + numberString
}
